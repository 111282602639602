import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { CoordinatesStep } from './CoordinatesStep';
import { MonthlyBillStep } from './MonthlyBillStep';
import { ResultStep } from './ResultStep';
import { ContactStep } from './ContactStep';
import { styled, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { addStep, setCurrentStep } from '../../store/reducer/stepsSlice';
import { useAppDispatch, useAppSelector } from '../../hooks/storeHooks';
import { isMobile } from '../../helpers/isMobileHelper';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import CheckIcon from '@mui/icons-material/Check';
import { sendContactData } from '../../store/reducer/contactDataSlice';
import { useTranslation } from 'react-i18next';
import { ElectricityPriceStep } from './ElectricityPriceStep';
import { useNavigate, useParams } from 'react-router-dom';

export const HorizontalLinearStepper = () => {
  const [activeStep, setActiveStep] = React.useState(0);
  const [animated, setAnimated] = useState(false);
  const [tooltipOpen, setOpen] = React.useState(false);

  const availableSteps = useAppSelector((state) => state.stepSlice.data);
  const currentSavedStep = useAppSelector((state) => state.stepSlice.currentStep);
  const contactData = useAppSelector((state) => state.contactDataSlice);
  const calculations = useAppSelector((state) => state.electric.data.content);
  const isBattery = useAppSelector((state) => state.batterySlice.isBatteryIncluded);

  const navigate = useNavigate();
  const { t } = useTranslation();
  let { number } = useParams();
  const steps = [t("coordinatesStep.header"), t("monthlyBillStep.header"), t("electricityPriceStep.header"), t("resultStep.header"), t("contactStep.header")];

  const dispatch = useAppDispatch();
  const theme = useTheme();

  useEffect(() => {
    setActiveStep(currentSavedStep);
  }, []);

  const setNextStepAvailable = (nextStep: number) => {
    addStep(nextStep)(dispatch);
  }

  const handleNext = () => {
    if (activeStep + 1 === steps.length) {
      sendContactData(contactData, calculations, isBattery)(dispatch);
    } else {
      // setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setCurrentStep(activeStep + 1)(dispatch);
      navigate(`/dashboards/calculator/step/${activeStep + 1}`);
    }
  };

  const handleBack = () => {
    // setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setCurrentStep(activeStep - 1)(dispatch);
    navigate(`/dashboards/calculator/step/${activeStep - 1}`);
  };

  const stepComponents = [
    <CoordinatesStep key={"first"} setNextStepAvailable={setNextStepAvailable} handleNext={handleNext}/>,
    <MonthlyBillStep key={"second"} setNextStepAvailable={setNextStepAvailable} handleNext={handleNext}/>,
    <ElectricityPriceStep key={"third"} setNextStepAvailable={setNextStepAvailable} handleNext={handleNext}/>,
    <ResultStep key={"fourth"} setNextStepAvailable={setNextStepAvailable} handleNext={handleNext} handleBack={handleBack} />,
    <ContactStep key={"fifth"} setNextStepAvailable={setNextStepAvailable} handleNext={handleNext}/>
  ];

  const getActiveStepComponent = () => {
    return stepComponents[activeStep];
  }

  useEffect(() => {
    if (availableSteps.some((el) =>  el === activeStep + 1)) {
      setAnimated(true);
    } else {
      setAnimated(false);
    }
  }, [availableSteps, activeStep]);

  useEffect(() => {
    const step = parseFloat(number);
    console.log("step", step);
    console.log("availableSteps", availableSteps);
    if (availableSteps.some(el => el === step)) {
      setActiveStep(step);
    } else {
      navigate("/dashboards/calculator/step/0");
    }
  }, [number]);

  useEffect(() => {
    setTimeout(() => {
      if (availableSteps.some((el) => el === activeStep + 1)) {
        handleOpen();

        setTimeout(() => {
          handleClose()
        }, 1000);
      }
    }, 20000);
  }, [availableSteps, activeStep]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <StyledWrapper>
      <Stepper activeStep={activeStep}
        sx={{
        paddingLeft: "12px",
        paddingRight: "12px",
        marginBottom: "12px",
        backgroundColor: theme.colors.primary.lighter,
        borderRadius: "8px"
      }}>
        {steps.map((label) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: {
            optional?: React.ReactNode;
          } = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{ isMobile() ? "" : label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <>
        {getActiveStepComponent()}
        {activeStep !== 3 && contactData.status !== "loaded" && <StepRowButtons isMobile={isMobile()}>
          <Button
            variant={'text'}
            color={'primary'}
            disabled={activeStep === 0}
            onClick={handleBack}
            sx={{
              width: { lg: '24%', xs: '45%' },
              visibility: activeStep > 0 ? "visible" : "hidden"
            }}
          >
            <ArrowBackIosNewOutlinedIcon sx={{ mr: 1 }} />
            <Typography variant='h4' color='inherit'>
              {t('stepper.back')}
            </Typography>
          </Button>
          <Box sx={{ width: "2%" }}/>
          <StyledButton
            animated={animated}
            variant={'contained'}
            color={'success'}
            onClick={handleNext}
            sx={{
              width: { lg: '24%', xs: '45%' },
              visibility: availableSteps.some((el) => el === activeStep + 1) ? "visible" : "hidden"
            }}
          >
            <Typography variant='h3' color='inherit'>
              {activeStep === steps.length - 1 ? t('send') : t('stepper.next')}
            </Typography>
            {activeStep === steps.length - 1
              ? <CheckIcon sx={{ ml: 1 }} />
              : <ArrowForwardIosOutlinedIcon sx={{ ml: 1 }} />
            }
          </StyledButton>
        </StepRowButtons>}
      </>
    </StyledWrapper>
  );
}

interface StepRowButtonsProps {
  readonly isMobile: boolean;
}

const StepRowButtons = styled(Box)<StepRowButtonsProps>`
  width: 100%;
  display: flex;
  flex-direction: row; 
  justify-content: center;
  padding-top: 12px;
  padding-bottom: 12px;
`;

interface ButtonAnimationProps {
  readonly animated: boolean;
}

export const StyledButton = styled(Button)<ButtonAnimationProps>`
  @keyframes tremble {
    0% {
      transform: rotate(10deg);
    }
    50% {
      transform: rotate(-10deg);
    }
    100% {
      transform: rotate(10deg);
    }
  }
   @keyframes smallVibration {
     0% { transform: translate(0, 0) rotate(0deg); }
     25% { transform: translate(4px, 4px) }
     50% { transform: translate(0, 0) rotate(-1eg); }
     75% { transform: translate(-4px, 4px) }
     100% { transform: translate(0, 0) rotate(0deg); }
   }
  // animation: ${({ animated }) => animated ? "smallVibration 0.7s linear infinite" : "none"};
  height: 50px
`;


const StyledWrapper = styled(Box)`
  width: 100%;
  height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
`;

// const LoadingIcon = styled(RefreshIcon)`
//   @keyframes rotation {
//     0% {
//       transform: rotate(0deg);
//     }
//     100% {
//       transform: rotate(360deg);
//     }
//   }
//     animation: rotation 2s linear infinite;
// `;
