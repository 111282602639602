import { useContext, useMemo } from 'react';

import {
  Box,
  alpha,
  Stack,
  lighten,
  Divider,
  IconButton,
  Tooltip,
  styled,
  useTheme, Button
} from '@mui/material';
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import { SidebarContext } from 'src/contexts/SidebarContext';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import { PopUpChatContainer } from '../../../content/applications/Messenger/components/PopUpChatContainer';
import { useLocation } from 'react-router-dom';
import { LanguageToggle } from '../../../components/languageToggle';
import Logo from '../../../components/LogoSign';
import { isMobile } from '../../../helpers/isMobileHelper';
import DesignServicesTwoToneIcon from '@mui/icons-material/DesignServicesTwoTone';
import { useTranslation } from 'react-i18next';

const HeaderWrapper = styled(Box)(
  ({ theme }) => `
        height: ${theme.header.height};
        color: ${theme.header.textColor};
        padding: ${theme.spacing(0, 2)};
        right: 0;
        z-index: 6;
        background-color: ${alpha(theme.header.background, 0.95)};
        backdrop-filter: blur(3px);
        position: fixed;
        justify-content: space-between;
        width: 100%;
        @media (min-width: ${theme.breakpoints.values.lg}px) {
            left: ${0};
            width: auto;
        }
`
);

function Header() {
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const theme = useTheme();
  const location = useLocation();
  const isMessenger = useMemo(() => {
    return location.pathname === "/dashboards/messenger";
  }, [location]);

  const { t } = useTranslation();
  return (
    <HeaderWrapper
      display="flex"
      alignItems="center"
      sx={{
        backgroundColor: lighten(theme.colors.primary.main, 0.95),
        boxShadow:
          theme.palette.mode === 'dark'
            ? `0 1px 0 ${alpha(
                lighten(theme.colors.primary.main, 0.7),
                0.15
              )}, 0px 2px 8px -3px rgba(0, 0, 0, 0.2), 0px 5px 22px -4px rgba(0, 0, 0, .1)`
            : `0px 2px 8px -3px ${alpha(
                theme.colors.alpha.black[100],
                0.2
              )}, 0px 5px 22px -4px ${alpha(
                theme.colors.alpha.black[100],
                0.1
              )}`
      }}
    >
      <Stack
        direction="row"
        divider={<Divider orientation="vertical" flexItem />}
        alignItems="center"
        spacing={2}
      >
        <Logo logoVariant={"green"}/>
        {/*<HeaderMenu />*/}
      </Stack>
      <Box display="flex" alignItems="center">
        <Button
          href="https://www.envirio.online/"
          target="_blank"
          rel="noopener noreferrer"
          startIcon={<DesignServicesTwoToneIcon />}
          variant={"outlined"}
          sx={{
            mr: 2
          }}
        >
          {t("overview")}
        </Button>
        {!isMessenger && !isMobile() && <PopUpChatContainer />}
        <Box sx={
          {
            ml:2,
            display: { lg: 'inline-block', xs: 'none' }
          }
        }>
          <LanguageToggle/>
        </Box>
        <Box
          sx={{
            ml: 2,
          }}
        >
          <Tooltip arrow title="Toggle Menu">
            <IconButton color="primary" onClick={toggleSidebar}>
              {!sidebarToggle ? (
                <MenuTwoToneIcon fontSize="small" />
              ) : (
                <CloseTwoToneIcon fontSize="small" />
              )}
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
    </HeaderWrapper>
  );
}

export default Header;
