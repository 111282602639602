import React  from 'react';
import {
  AppBar,
  Box,
  Button,
  Dialog,
  IconButton,
  Slide,
  styled,
  Toolbar,
  Typography
} from '@mui/material';
import RoomIcon from '@mui/icons-material/Room';
import CloseIcon from '@mui/icons-material/Close';
import { isMobile } from '../../../../helpers/isMobileHelper';
import { MapContainer, TileLayer } from 'react-leaflet';
import { useAppDispatch, useAppSelector } from '../../../../hooks/storeHooks';
import { TransitionProps } from '@mui/material/transitions';
import { DraggableMarker, IMapCoords } from './DraggableMarker';
import { ICoordinates, setCoordinates } from '../../../../store/reducer/electricSlice';
import { useTranslation } from 'react-i18next';

export const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const MapPopup = () => {
  const coords = useAppSelector((state) => state.electric.data?.content?.coordinates);

  const { t } = useTranslation();

  const coordsForMarker: IMapCoords = {
    lat: coords.lat,
    lng: coords.lon,
  };

  const dispatch = useAppDispatch();
  const [isOpen, setOpen] = React.useState(false);
  const [selectedPosition, setPosition] = React.useState<ICoordinates>(coords);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSaveCoords = () => {
    setCoordinates(selectedPosition)(dispatch);
    handleClose();
  }

  const handleChangeMarkerPosition = (newPosition: IMapCoords) => {
    const newCoords: ICoordinates = {
      lat: newPosition.lat,
      lon: newPosition.lng
    };
    setPosition(newCoords);
  }

  const isFullscreen = isMobile();
  return (
    <RootWrapper>
      <Button
        variant="outlined"
        startIcon={<RoomIcon fontSize="small" />}
        onClick={handleClickOpen}
        sx={{ marginBottom: '10px', marginTop: '10px', minWidth: '100px', width: '100%', height: '50px' }}
        size="medium"
      >
        {t("coordinatesStep.pickOnMap")}
      </Button>
      <Dialog
        fullScreen={isFullscreen}
        onClose={handleClose}
        open={isOpen}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
              sx={{marginLeft: "8px"}}
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {t("coordinatesStep.mapPopupHeader")}
            </Typography>
          </Toolbar>
        </AppBar>
        <Wrapper>
          <MapContainer
            style={{height: "100%", width: "100%"}}
            center={coordsForMarker}
            zoom={7}
            scrollWheelZoom={true}
          >
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <DraggableMarker center={coordsForMarker} onChangeMarkerPosition={handleChangeMarkerPosition}/>
          </MapContainer>
        </Wrapper>
        <Button
          autoFocus
          variant={"contained"}
          color={'success'}
          sx={{ fontSize: "24px", borderRadius: "0" }}
          onClick={handleSaveCoords}
        >
          {t("save")}
        </Button>
      </Dialog>
    </RootWrapper>
  );
}

const Wrapper = styled(Box)`
  width: 600px;
  height: 600px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

const RootWrapper = styled(Box)`
  
`;
