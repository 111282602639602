const mockHostnames = ["envirio.online", "localhost"];

export const mainHostname = "envirio.me";

export const testUrlForMock = () => {
  const path = getOutsideHostname();
  return mockHostnames.includes(path);
}

export const testUrlForLocalhost = () => {
  const path = window.location;
  return path.hostname === "localhost";
}

export const testUrlForMainPage = () => {
  const path = window.location;
  return path.hostname === mainHostname;
}

export const getOutsideUrl = () => {
  return (window.location != window.parent.location)
    ? document.referrer
    : document.location.href;
}

const getOutsideHostname = () => {
  if (window.location != window.parent.location) {
    return new URL(document.referrer).hostname;
  } else {
    return document.location.hostname;
  }
}

