import { Box, Grid, styled, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { StyledButton } from './Stepper';
import { Highlight } from '../Text';

let logoFile: string = "/static/images/placeholders/illustrations/ZeroStep.png";

export const ZeroStep = () => {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const handleStart = () => {
    navigate("/dashboards/calculator/step/0");
  }

  return (
    <Wrapper sx={{
      height: { lg: "70vh" },
      marginTop: { lg: "60px", xs: "28px" },
      paddingBottom: { xs: "20px" }
    }}>
      <Grid container spacing={4}>
        <Grid item xs={12} lg={6}>
          <Box
            sx={{
              width: "95%",
              marginLeft: "5%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "start",
            }}
          >
            <Typography variant={"body1"} fontSize={22} sx={{ width: "80%" }}>
              <Trans i18nKey={"helloText"} components={{ 1: <br />, 2: <Highlight/>, 3: <Bold/>, 4: <Small/> }}/>
            </Typography>
            <StyledButton
              animated={false}
              variant={'contained'}
              color={'success'}
              onClick={handleStart}
              sx={{
                width: { lg: '35%', xs: '45%' },
                mt: 2,
              }}
            >
              <Typography variant='h3' color='inherit'>
                {t("start")}
              </Typography>
            </StyledButton>
          </Box>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Box sx={{
            display: "flex",
            justifyContent: "center"
          }}>
            <Box
              sx={{
                width: { lg: "100%", xs: "80%" },
              }}
            >
              <img src={logoFile} alt='logo' width={"100%"} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Wrapper>
  );
}

const Wrapper = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const Bold = styled(Typography)`
  font-size: 25px;
  font-weight: bold;
`;

const Small = styled(Typography)`
  font-size: 16px;
  font-weight: lighter;
`;
