import { combineReducers } from "@reduxjs/toolkit";
import solarReducer from "./solarSlice";
// import windReducer from "./windSlice";
import userReducer from "./userSlice";
import electricReducer from "./electricSlice";
import distributorSlice from "./distributorSlice";
import panelReducer from "./panelSlice";
import currentPanelReducer from "./currentPanelSlice";
import filterSlice from "./filterSlice";
import favoriteSlice from "./favoriteSlice";
import configSlice from "./featureConfigSlice";
import aiChatSlice from "./aiChatSlice";
import propertyTypeSlice from "./propertyTypeSlice";
import stepSlice from "./stepsSlice";
import batterySlice from "./batterySlice";
import contactDataSlice from "./contactDataSlice";
import currenciesSlice from './currenciesSlice';

const rootReducer = combineReducers({
  solar: solarReducer,
  distributor: distributorSlice,
  // wind: windReducer,
  electric: electricReducer,
  panels: panelReducer,
  currentPanel: currentPanelReducer,
  user: userReducer,
  filters: filterSlice,
  favorite: favoriteSlice,
  config: configSlice,
  aiChat: aiChatSlice,
  propertyType: propertyTypeSlice,
  stepSlice: stepSlice,
  batterySlice: batterySlice,
  contactDataSlice: contactDataSlice,
  currencies: currenciesSlice
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
