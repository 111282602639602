import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { StyledImage } from './BatteryInfo';

export const WarrantyInfo = () => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        mt: 2,
        padding: 2
      }}
    >
      <Typography variant={'h3'} color={"text.primary"}>
        {t("resultStep.sidebar.envirioGuarantee")}
      </Typography>
      <Typography
        variant={"body2"}
        color={"text.primary"}
        fontSize={"20px"}
        sx={{
          mt: 2
        }}
      >
        {t("resultStep.sidebar.guaranteeText")}
      </Typography>
      <StyledImage
        src='https://history.vattenfall.com/globalassets/history/vf300005.jpg'
        alt=''

      />
    </Box>
  );
}

