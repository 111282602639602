import React, { useEffect } from 'react';
import { Box, Button, Dialog, IconButton, styled, Toolbar, Tooltip, Typography } from '@mui/material';
import { AiChat } from '../AiChat';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import { isMobile } from '../../../../helpers/isMobileHelper';
import { useNavigate } from 'react-router-dom';
import { ConfigTypes } from '../../../../featureConfig';
import { useAppSelector } from '../../../../hooks/storeHooks';
import CloseIcon from '@mui/icons-material/Close';
import { Transition } from '../../../dashboards/Calculator/map/mapPopup';
import { useTranslation } from 'react-i18next';

export const PopUpChatContainer = () => {
  const navigate = useNavigate();
  const [isOpen, setOpen] = React.useState(false);
  const [isOpenTooltip, setOpenTooltip] = React.useState(true);

  const { t } = useTranslation();

  const featureConfig = useAppSelector((state) => state.config.data);
  const isIframe = featureConfig.type === ConfigTypes.iframeFeatureConfig;

  const isFullscreen = isMobile() && isIframe;
  const isRedirect = isMobile() && !isIframe;

  const handleClickOpen = () => {
    if (isRedirect) {
      navigate("/dashboards/messenger");
    } else {
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseTooltip = () => {
    setOpenTooltip(false);
  }

  const handleOpen = () => {
    setOpenTooltip(true);
  }

  useEffect(() => {
    setTimeout(() => {
      setOpenTooltip(false);
    }, 5000);
  }, []);

  return (
    <RootWrapper>
      <Tooltip open={isOpenTooltip} onOpen={handleOpen} onClose={handleCloseTooltip} title={
        <React.Fragment>
          <Typography variant={"h4"} color="inherit">{t("aiChat.tooltip")}</Typography>
        </React.Fragment>
      } arrow>
        <Button
          variant="contained"
          startIcon={<AddTwoToneIcon fontSize="small" />}
          onClick={handleClickOpen}
        >
          {t("aiChat.openAiChat")}
        </Button>
      </Tooltip>
      <Dialog
        fullScreen={isFullscreen}
        onClose={handleClose}
        open={isOpen}
        TransitionComponent={Transition}
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
            sx={{marginLeft: "8px"}}
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {t("aiChat.aiSupportChat")}
          </Typography>
        </Toolbar>
        <Wrapper isFullscreen={isFullscreen}>
          <AiChat/>
        </Wrapper>
      </Dialog>
    </RootWrapper>
  );
}

interface IWrapperProps {
  isFullscreen: boolean;
}

const Wrapper = styled(Box)<IWrapperProps>`
  width: ${({ isFullscreen }) => isFullscreen ? "100%" : "600px"};
  height: ${({ isFullscreen }) => isFullscreen ? "100%" : "600px"};
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

const RootWrapper = styled(Box)`
  
`;
