import { IDistributor } from '../../../types/panelTypes';

export const mockDistributors: IDistributor[] = [
  {
    id: 9999999999,
    companyName: "E-Prime",
    phoneNumber: "+456462523358",
    countryCode: "MD",
    location: "Europe, Moldova",
    description: "Chisinau Solar Panel Company",
    address: "str. Legeweg 35",
  },
  {
    id: 9999999998,
    companyName: "SolarTech",
    phoneNumber: "+23442334258",
    countryCode: "SE",
    location: "Europe",
    description: "Luxury Solar Panel Company",
    address: "str. Alsnogatan 18",
  },
  {
    id: 9999999997,
    companyName: "Brazil Solar Tech",
    phoneNumber: "+43674377338",
    countryCode: "BR",
    location: "Latin America",
    description: "Solar Panel Company",
    address: "Av. Lins de Vasconcelos, 1222 - Aclimação, São Paulo - SP, 01538-001",
  },
];
