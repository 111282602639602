import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppDispatch } from "../index";

export interface ISteps {
  data: number[];
  currentStep: number;
}

const initialState: ISteps = {data: [0], currentStep: 0};

const stepSlice = createSlice({
  name: "stepSlice",
  initialState,
  reducers: {
    addAvailableStep(state, action: PayloadAction<number>) {
      state.data.push(action.payload);
    },

    setCurrentStepIndex(state, action: PayloadAction<number>) {
      state.currentStep = action.payload;
    },

    makeAllPreviousAvailable(state, action: PayloadAction<number>) {
      const available = state.data;
      for (let i = 0; i < action.payload; i++) {
        if (!available.some((el) => el === i)) {
          state.data.push(i);
        }
      }
    },

  },
});

const { addAvailableStep, setCurrentStepIndex } = stepSlice.actions;
export default stepSlice.reducer;

export const addStep =
  (data: number) => async (dispatch: AppDispatch) => {
    dispatch(addAvailableStep(data));
  };

export const setCurrentStep =
  (data: number) => async (dispatch: AppDispatch) => {
    dispatch(setCurrentStepIndex(data));
  };
