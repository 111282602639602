import { FC, ReactNode, useEffect } from 'react';
import { Box, alpha, lighten, useTheme } from '@mui/material';
import { Outlet } from 'react-router-dom';

import Sidebar from './Sidebar';
import Header from './Header';
import { useAppDispatch, useAppSelector } from '../../hooks/storeHooks';
import { initEmptyUser } from '../../store/reducer/userSlice';
import { setFeatureConfig } from '../../store/reducer/featureConfigSlice';
import { ConfigTypes, iframeFeatureConfig } from '../../featureConfig';
import { testUrlForMainPage } from '../../helpers/isMockHelper';

interface SidebarLayoutProps {
  children?: ReactNode;
}

const SidebarLayout: FC<SidebarLayoutProps> = () => {
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector((state) => state.user.data.content);
  const featureConfig = useAppSelector((state) => state.config.data);
  // const { sidebarToggle } = useContext(SidebarContext);

  useEffect(() => {
    if (!testUrlForMainPage()) {
      // TODO change to test themes
      setFeatureConfig(iframeFeatureConfig)(dispatch);
    }
  }, []);

  useEffect(() => {
    initEmptyUser()(dispatch);
  }, [currentUser]);

  const theme = useTheme();

  return (
    <>
      <Box
        sx={{
          flex: 1,
          height: '100%',

          '.MuiPageTitle-wrapper': {
            background:
              theme.palette.mode === 'dark'
                ? theme.colors.alpha.trueWhite[5]
                : theme.colors.alpha.white[50],
            marginBottom: `${theme.spacing(4)}`,
            boxShadow:
              theme.palette.mode === 'dark'
                ? `0 1px 0 ${alpha(
                  lighten(theme.colors.primary.main, 0.7),
                  0.15
                )}, 0px 2px 4px -3px rgba(0, 0, 0, 0.2), 0px 5px 12px -4px rgba(0, 0, 0, .1)`
                : `0px 2px 4px -3px ${alpha(
                  theme.colors.alpha.black[100],
                  0.1
                )}, 0px 5px 12px -4px ${alpha(
                  theme.colors.alpha.black[100],
                  0.05
                )}`
          }
        }}
      >
        {featureConfig.topBar && <Header />}
        {featureConfig.sidebar && <Sidebar />}
        <Box
          sx={{
            backgroundColor: featureConfig.type === ConfigTypes.iframeFeatureConfig
              ? 'transparent'
              : lighten(theme.colors.primary.main, 0.95),
            minHeight: '100vh',
            position: 'relative',
            zIndex: 5,
            display: 'block',
            flex: 1,
            pt: featureConfig.topBar ? `${theme.header.height}` : 2,
            pb: featureConfig.topBar ? 0 : 2,
            [theme.breakpoints.up('lg')]: {
              ml: featureConfig.sidebar ? `${0}` : 0
            }
          }}
        >
          <Box display="block">
            <Outlet/>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default SidebarLayout;
