import axios, { AxiosInstance } from 'axios';
import { ITokenData, IUserLoginObject, IUserRegisterObject } from '../store/reducer/userSlice';

export const client: AxiosInstance = axios.create({
  baseURL: "https://envirio.me:8080",
  // baseURL: "http://192.168.31.77:8080",
  proxy: false,
  timeout: 5000,
  headers: {
    "Access-Control-Allow-Origin": "*",
  },
});

export const aiClient: AxiosInstance = axios.create({
  baseURL: "https://envirio.me:8080",
  proxy: false,
  timeout: 30000,
  headers: {
    "Access-Control-Allow-Origin": "*",
  },
});

client.interceptors.request.use((config) => {
  if (isUrlNeedToken(config.url)) {
    const token = localStorage.getItem("envirio_token");
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
  }
  return config;
}, (error) => {
  const status = error.response ? error.response.status : null;
  if (status === 401) {
    if (isUrlNeedToken(error.config.url)) {
      return refreshToken().then(token => {
        if (token) {
          error.config.headers['Authorization'] = 'Bearer ' + token;
          error.config.baseURL = undefined;
          return client.request(error.config);
        } else {
          return Promise.reject(error);
        }
      });
    }
  }
  return Promise.reject(error);
});

type getTokenType = "login" | "register";

export const getToken = async (data: IUserRegisterObject | IUserLoginObject, type: getTokenType) => {
  let initialTokenData;
  if (type === "login") {
    initialTokenData = await client.post<ITokenData>(
      "api/login",
      data
    );
  }
  if (type === "register") {
    initialTokenData = await client.post<ITokenData>(
      "api/register",
      data
    );
  }
  localStorage.setItem("envirio_token", initialTokenData.data.token);
  localStorage.setItem("envirio_refresh_token", initialTokenData.data.refreshToken);
}

export const refreshToken = async () => {
  const refreshToken = localStorage.getItem("envirio_refresh_token");

  const newToken = await client.post<string>(
    "api/refreshToken",
    refreshToken
  );
  localStorage.setItem("envirio_token", newToken.data);
  return newToken.data;
}

const isUrlGetToken = (url: string) => {
  return url === "/api/login" || url === "/api/register";
}

const isUrlRefreshToken = (url: string) => {
  return url === "/api/refreshToken";
}

const isUrlNeedToken = (url: string) => {
  return (!isUrlGetToken(url) && !isUrlRefreshToken(url));
}
