import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppDispatch } from "../index";

export interface IBatteryInfo {
  isBatteryIncluded: boolean;
}

const initialState: IBatteryInfo = {isBatteryIncluded: true};

const batterySlice = createSlice({
  name: "batterySlice",
  initialState,
  reducers: {
    setBatteryState(state, action: PayloadAction<boolean>) {
      state.isBatteryIncluded = action.payload;
    },
  },
});

const { setBatteryState } = batterySlice.actions;
export default batterySlice.reducer;

export const setIsBatteryIncluded =
  (data: boolean) => async (dispatch: AppDispatch) => {
    dispatch(setBatteryState(data));
  };
