export type ILoadingStatus = "init" | "loading" | "loaded" | "waiting" | "progress" | "error";

export interface ContentLoading<T> {
    content: T;
    status: ILoadingStatus;
    error?: Error | null;
}

export const initialContentLoading = <T>(value: T): ContentLoading<T> => ({
    content: value,
    status: "init",
    error: undefined,
});
