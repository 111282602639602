import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppDispatch } from "../index";
import { ContentLoading, initialContentLoading } from "../commonTypes";

export interface ITokenData {
  token: string;
  refreshToken: string;
}

export interface IUserRegisterObject {
  email: string;
  password: string;
  isSignedToUpdates?: boolean;
}

export interface IUserLoginObject {
  email: string;
  password: string;
  isRemember?: boolean;
}

export interface IUser {
  email: string;
  name: string;
  phoneNumber: string;
  avatar?: string;
  id?: number;
}

export interface IUserData {
  data?: ContentLoading<null | IUser>;
}

const emptyUser: IUser = {
  email: "no email",
  name: "Default User",
  phoneNumber: "no phone",
  avatar: "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__480.png",
  id: 128812,
};

const initialState = (): IUserData => {
  const savedUserString = localStorage.getItem("currentEnvirioUser");
  if (savedUserString) {
    const savedUser = JSON.parse(savedUserString);
    return { data: initialContentLoading(savedUser) }
  }
  return { data: initialContentLoading(null) };
};

const userSlice = createSlice({
  name: "userSlice",
  initialState,
  reducers: {
    startGetUser(state) {
      state.data = {
        content: null,
        status: "loading",
        error: undefined,
      };
    },
    startUpdateUser(state) {
      state.data = {
        content: state.data.content,
        status: "loading",
        error: undefined,
      };
    },
    changeUserData(state, action: PayloadAction<Partial<IUser>>) {
      state.data = {
        content: {...state.data.content, ...action.payload},
        status: "loaded",
        error: undefined,
      };
    },
    setUserData(state, action: PayloadAction<IUser>) {
      state.data = {
        content: action.payload,
        status: "loaded",
        error: undefined,
      };
    },
    setError(state, action: PayloadAction<Error>) {
      state.data = {
        content: null,
        status: "error",
        error: action.payload,
      };
    },
    signOut(state) {
      state.data = {
        content: null,
        status: "init",
        error: undefined,
      };
    },
  },
});

const { startUpdateUser, startGetUser, changeUserData, setUserData, setError, signOut } = userSlice.actions;
export default userSlice.reducer;

export const registerUser =
  (data: IUserRegisterObject) => async (dispatch: AppDispatch) => {
    try {
      // dispatch(startGetUser());
      // await getToken(data, "register");
      // const userData = await client.post<IRootObject<IUser>>(
      //   "/api/newUser",
      // );
      // if (!(userData.status === 200)) {
      //   const err = new Error("Wrong response body");
      //   dispatch(setError(err));
      // }
      // dispatch(setUserData(userData.data.data));
      const user = initialContentLoading(emptyUser);
      dispatch(setUserData(user.content));
    } catch (e) {
      if (typeof e === "string") {
        const err = new Error(e);
        dispatch(setError(err));
      } else if (e instanceof Error) {
        dispatch(setError(e));
      }
    }
  }

export const loginToUser =
  (data: IUserLoginObject) => async (dispatch: AppDispatch) => {
  console.log(data);
    try {
      // dispatch(startGetUser());
      // await getToken(data, "login");
      // const userData = await client.post<IRootObject<IUser>>(
      //   "/api/login",
      //   { data }
      // );
      // dispatch(setUserData(userData.data.data));
      dispatch(setUserData(emptyUser));
    } catch (e) {
      if (typeof e === "string") {
        const err = new Error(e);
        dispatch(setError(err));
      } else if (e instanceof Error) {
        dispatch(setError(e));
      }
    }
  }

export const initEmptyUser =
  () => async (dispatch: AppDispatch) => {
    const distributor = initialContentLoading(emptyUser);
    dispatch(setUserData(distributor.content));
  }

export const updateUserData =
  (userData: Partial<IUser>) => async (dispatch: AppDispatch) => {
    dispatch(changeUserData(userData));
  };

export const userSignOut = () => (dispatch: AppDispatch) => {
  localStorage.removeItem("isEnvirioUserRemember");
  localStorage.removeItem("currentEnvirioUser");
  dispatch(signOut());
}
