import { DataInputs } from '../../content/dashboards/Calculator/dataInputs';
import { FC, useEffect } from 'react';
import { IStep } from './CoordinatesStep';
import { Box } from '@mui/material';

export const ElectricityPriceStep: FC<IStep> = ({setNextStepAvailable}) => {
  useEffect(() => {
    setNextStepAvailable(3);
  }, []);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: "24px",
        height: "70vh"
      }}
    >
      <DataInputs/>
    </Box>
  );
}
