import { IConsumptionData } from '../constants/consumption';

export const anyToNumberConvert = (value: any) => {
  if (typeof value === 'string') {
    const numberValue = parseFloat(value);
    return isNaN(numberValue) ? 0 : numberValue;
  }
  if (typeof value === 'number') {
    return isNaN(value) ? 0 : value;
  }
  else {
    return 0;
  }
}

export type IStringedData<Type> = {
  [Property in keyof Type]: string;
}

export type INumberData<Type> = {
  [Property in keyof Type]: number;
}

export const objectPropertiesToString = <Type>(object: Type): IStringedData<Type> => {
  const newObject = {};
  for (const [key, value] of Object.entries(object)) {
    newObject[key] = value.toString();
  }
  return newObject as IStringedData<Type>;
}

export const objectPropertiesToNumber = <Type>(object: Type): INumberData<Type> => {
  const newObject = {};
  for (const [key, value] of Object.entries(object)) {
    newObject[key] = anyToNumberConvert(value);
  }
  return newObject as INumberData<Type>;
}
