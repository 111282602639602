import * as React from 'react';
import Typography from '@mui/material/Typography';
import { useAppSelector } from '../../../hooks/storeHooks';
import {XYPlot, XAxis, YAxis, HorizontalGridLines, VerticalGridLines, VerticalBarSeries} from 'react-vis';
import { Grid } from '@mui/material';
import AutoSizer from 'react-virtualized-auto-sizer';
import { styled } from '@mui/material/styles';
import { isMobile } from '../../../helpers/isMobileHelper';
import { useTranslation } from 'react-i18next';


const months = ["jan", "feb", "mar", "apr", "may", "jun", "jul", "aug", "sep", "oct", "nov", "dec"];

export const AdditionalInfo = () => {
  const solarData = useAppSelector((state) => state.solar.data);

  const { t } = useTranslation();
  const isShowIndex = isMobile();
  const temperaturesData = solarData.content?.data?.airTemperaturePerMonth?.map((temperature, index) => ({
    x: isShowIndex ? index + 1 : months[index],
    y: temperature })
  ) || [];
  const pvOutData = solarData.content?.data?.pvOutPerMonth?.map((pvOut, index) => ({
    x: isShowIndex ? index + 1 : months[index],
    y: pvOut })
  ) || [];
  return (
    <div>
      <Typography variant="h5" color={"text.secondary"}>
        {t("chart.optimalTilt")}: {solarData.content?.data?.optimumTilt}°
      </Typography>
      <Grid container spacing={2}>
        <ChartGrid item xs={12}>
          <Typography variant="h4" sx={{margin: "15px 0px"}} color={"text.secondary"}>
            {t("chart.avgMonthlyTemp")}
          </Typography>
          <AutoSizer>
            {({height, width}) => (
              <XYPlot
                width={width}
                height={300}
                xType="ordinal"
              >
                <VerticalBarSeries
                  width={width}
                  data={temperaturesData}
                  style={{}}
                />
                {/*<LabelSeries xOffset="500" data={temperaturesData} getLabel={elem => elem.x} />*/}
                <VerticalGridLines />
                <HorizontalGridLines />
                <XAxis/>
                <YAxis/>
                <HorizontalGridLines/>
              </XYPlot>
            )}
          </AutoSizer>
        </ChartGrid>
        <ChartGrid item xs={12}>
          <Typography variant="h4" sx={{margin: "15px 0px"}} color={"text.secondary"}>
            {t("chart.avgMonthlyEfficiency")}
          </Typography>
          <AutoSizer>
            {({height, width}) => (
              <XYPlot
                width={width}
                height={300}
                xType="ordinal"
              >
                <VerticalBarSeries
                  width={width}
                  data={pvOutData}
                  style={{}}
                />
                <VerticalGridLines />
                <HorizontalGridLines />
                <XAxis/>
                <YAxis/>
                <HorizontalGridLines/>
              </XYPlot>
            )}
          </AutoSizer>
        </ChartGrid>
      </Grid>
      {/*<Accordion disabled={!temperaturesData.length}>*/}
      {/*  <AccordionSummary*/}
      {/*    expandIcon={<ExpandMoreIcon />}*/}
      {/*    aria-controls="panel1a-content"*/}
      {/*    id="panel1a-header"*/}
      {/*  >*/}
      {/*    <Typography>Additional info</Typography>*/}
      {/*  </AccordionSummary>*/}
      {/*  <AccordionDetails>*/}
      {/*    <Typography variant="h5">*/}
      {/*      Optimal tilt of the solar panel: {solarData.content?.data?.optimumTilt}°*/}
      {/*    </Typography>*/}
      {/*    <Grid container spacing={2}>*/}
      {/*      <ChartGrid item xs={12} md={6} lg={6}>*/}
      {/*        <Typography variant="h4" sx={{margin: "15px 0px"}}>*/}
      {/*          Avg. monthly temperatures*/}
      {/*        </Typography>*/}
      {/*        <AutoSizer>*/}
      {/*          {({height, width}) => (*/}
      {/*            <XYPlot*/}
      {/*              width={width}*/}
      {/*              height={300}*/}
      {/*              xType="ordinal"*/}
      {/*            >*/}
      {/*              <VerticalBarSeries*/}
      {/*                width={width}*/}
      {/*                data={temperaturesData}*/}
      {/*                style={{}}*/}
      {/*              />*/}
      {/*              /!*<LabelSeries xOffset="500" data={temperaturesData} getLabel={elem => elem.x} />*!/*/}
      {/*              <VerticalGridLines />*/}
      {/*              <HorizontalGridLines />*/}
      {/*              <XAxis/>*/}
      {/*              <YAxis/>*/}
      {/*              <HorizontalGridLines/>*/}
      {/*            </XYPlot>*/}
      {/*          )}*/}
      {/*        </AutoSizer>*/}
      {/*      </ChartGrid>*/}
      {/*      <ChartGrid item xs={12} md={6} lg={6}>*/}
      {/*        <Typography variant="h4" sx={{margin: "15px 0px"}}>*/}
      {/*          Monthly efficiency multipliers*/}
      {/*        </Typography>*/}
      {/*        <AutoSizer>*/}
      {/*          {({height, width}) => (*/}
      {/*            <XYPlot*/}
      {/*              width={width}*/}
      {/*              height={300}*/}
      {/*              xType="ordinal"*/}
      {/*            >*/}
      {/*              <VerticalBarSeries*/}
      {/*                width={width}*/}
      {/*                data={pvOutData}*/}
      {/*                style={{}}*/}
      {/*              />*/}
      {/*              <VerticalGridLines />*/}
      {/*              <HorizontalGridLines />*/}
      {/*              <XAxis/>*/}
      {/*              <YAxis/>*/}
      {/*              <HorizontalGridLines/>*/}
      {/*            </XYPlot>*/}
      {/*          )}*/}
      {/*        </AutoSizer>*/}
      {/*      </ChartGrid>*/}
      {/*    </Grid>*/}
      {/*  </AccordionDetails>*/}
      {/*</Accordion>*/}
    </div>
  );
}

const ChartGrid = styled(Grid)`
  height: 350px;
  //@media (max-width: 400px) {
  //  height: 200px;
  //}
`;
