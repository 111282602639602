import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MessageType } from "react-chat-elements";
import { AppDispatch } from "../index";
import { aiClient } from '../../api/AxiosInstance';
import { IRootObject } from '../../types/panelTypes';
import { v4 as uuidv4 } from 'uuid';
import { ContentLoading, initialContentLoading } from '../commonTypes';
import { playMessageSound } from '../../content/applications/Messenger/AiChat';

interface IChat {
  messages: MessageType[];
}

export interface IChatData {
  data?: ContentLoading<IChat>;
}

const initialState: IChatData = { data: initialContentLoading({
  messages: []
})};

const aiChatSlice = createSlice({
  name: "aiChatSlice",
  initialState,
  reducers: {
    addMessage(state, action: PayloadAction<MessageType>) {
      state.data.content.messages.push(action.payload);
      state.data.status = "loaded";
    },
    startLoad(state) {
      state.data.status = "loading";
    },
    setError(state, action: PayloadAction<Error>) {
      state.data = {
        content: state.data.content,
        status: "error",
        error: action.payload,
      };
    },
  },
});

const { addMessage, startLoad, setError } = aiChatSlice.actions;
export default aiChatSlice.reducer;

type Message = {
  role: string,
  content: string
}

type titles = "You" | "Envi";

const convertTitleToRole = (title: titles) => {
  if (title === "You") {
    return "user";
  } if (title === "Envi") {
    return "assistant";
  }
}

export const sendMessageToChat = (message: string, messageHistory: MessageType[]) => async (dispatch: AppDispatch) => {
  const messageObject: MessageType = {
      id: uuidv4(),
      replyButton: false,
      removeButton: false,
      focus: false,
      status: "read",
      notch: false,
      retracted: false,
      titleColor: "lightblue",
      date: Date.now(),
      forwarded: false,
      position:"right",
      type:"text",
      title:"You",
      text: message,
    };
  dispatch(addMessage(messageObject));
  dispatch(startLoad());

  const messageHistoryToSend: Message[] = messageHistory.map((el) =>
    ({ content: el.text, role: convertTitleToRole(el.title as titles) })
  );
  try {
    const aiAnswer = await aiClient.post<IRootObject<string>>(
      "/support/ai",
      {
        question: message,
        history: messageHistoryToSend
      }
    );
    const answerObject: MessageType = {
      id: uuidv4(),
      replyButton: false,
      removeButton: false,
      focus: false,
      status: "read",
      notch: false,
      retracted: false,
      titleColor: "#5569ff",
      date: Date.now(),
      forwarded: false,
      position:"left",
      type:"text",
      title:"Envi",
      text: aiAnswer.data.data,
    };
    dispatch(addMessage(answerObject));
    playMessageSound();
  } catch (e) {
    if (typeof e === "string") {
      const err = new Error(e);
      dispatch(setError(err));
    } else if (e instanceof Error) {
      dispatch(setError(e));
    }
  }
};

