import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppDispatch } from "../index";
import { defaultFeatureConfig, IFeatureConfig } from '../../featureConfig';

export interface IFeatureConfigData {
  data?: IFeatureConfig;
}

const initialState: IFeatureConfigData = { data: defaultFeatureConfig };

const featureConfigSlice = createSlice({
  name: "featureConfigSlice",
  initialState,
  reducers: {
    setFeatureConfigData(state, action: PayloadAction<IFeatureConfig>) {
      state.data = action.payload;
    },
  },
});

const { setFeatureConfigData } = featureConfigSlice.actions;
export default featureConfigSlice.reducer;

export const setFeatureConfig = (config: IFeatureConfig) => (dispatch: AppDispatch) => {
  dispatch(setFeatureConfigData(config));
};
