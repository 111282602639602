import {
  Box, Button,
  Card, CardActionArea,
  CardContent, CardMedia,
  FormControlLabel,
  Grid, IconButton, lighten,
  styled, Switch,
  Typography, useTheme
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../hooks/storeHooks';
import { anyToNumberConvert } from '../../../../helpers/transformations/objectTransformations';
import CheckIcon from '@mui/icons-material/Check';
import WbSunnyOutlinedIcon from '@mui/icons-material/WbSunnyOutlined';
import CardGiftcardOutlinedIcon from '@mui/icons-material/CardGiftcardOutlined';
import { infoSidebarContentType } from '../../../../components/Stepper/ResultStep';
import { setCurrentPanel } from '../../../../store/reducer/currentPanelSlice';
import { setIsBatteryIncluded } from '../../../../store/reducer/batterySlice';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import { useTranslation } from 'react-i18next';

interface IProps {
  openInfoSidebar: (type: infoSidebarContentType) => void;
  handleNext: () => void;
  handleBack: () => void;
}

// const batteryPricesPerKwh: Record<number, number>[] = [
//   { 1000: 5000 },
//   { 3000: 10000 },
//   { 7000: 17000 },
// ];

const batteryMultiplier = 0.4;

export const MainCurrentPanel = ({ openInfoSidebar, handleNext, handleBack }: IProps) => {
  const [isAnimated, setAnimated] = useState<boolean>(false);
  const [panelAmount, setPanelAmount] = useState<number>(0);
  const [soldAmount, setSoldAmount] = useState<number>(0);
  const [sellIncome, setSellIncome] = useState<number>(0);
  const [returnTime, setReturnTime] = useState<number>(0);
  const [overallPrice, setOverallPrice] = useState<number>(0);
  const [annualGeneration, setAnnualGeneration] = useState<number>(0);
  const [tooltipOpen, setOpen] = React.useState(false);
  // const [isBatteryIncluded, setIsBatteryIncluded] = useState<boolean>(false);

  const theme = useTheme();

  const currentPanel = useAppSelector((state) => state.currentPanel.data);
  const panelData = useAppSelector((state) => state.panels.data);

  // const isSolarDataFetched = useAppSelector((state) => state.solar.data?.content?.data.pvOutAnnual);
  // const isPanelsFetched = useAppSelector((state) => state.panels.data?.content?.data.length);

  const solarData = useAppSelector((state) => state.solar.data);
  const annualConsumption = useAppSelector(
    (state) => state.electric.data?.content?.consumption.annualConsumption
  );
  const sellingPrice = useAppSelector(
    (state) => state.electric.data?.content?.consumption.electricitySellingPrice
  );
  const annualBill = useAppSelector(
    (state) => state.electric.data?.content?.bills.annualBill
  );
  // const annualCo2 = useAppSelector(
  //   (state) => state.electric.data?.content?.bills.annualCo2
  // );
  const currencySign = useAppSelector(
    (state) => state.electric.data?.content?.currencySign
  );

  const isBatteryIncluded = useAppSelector(
    (state) => state.batterySlice.isBatteryIncluded
  );

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    setCurrentPanel(panelData.content.data[0])(dispatch);
  }, []);

  useEffect(() => {
    if (
      solarData?.content?.data.pvOutAnnual &&
      annualConsumption &&
      currentPanel?.specs.maxPower_W
    ) {
      const needKwh = annualConsumption / solarData?.content?.data.pvOutAnnual;
      const needWh = needKwh * 1000;
      const floatAmount = needWh / currentPanel?.specs.maxPower_W;
      const ceilAmount = Math.ceil(floatAmount);
      setPanelAmount(ceilAmount);
    }
  }, [annualConsumption, solarData, currentPanel]);

  useEffect(() => {
    const currentOnePanelGenerationW =
      ((solarData?.content?.data.pvOutAnnual || 1000) *
        currentPanel?.specs.maxPower_W);
    const currentAllPanelGenerationW = currentOnePanelGenerationW * panelAmount;
    const currentAllPanelGenerationKw = currentAllPanelGenerationW / 1000;
    setAnnualGeneration(currentAllPanelGenerationKw);
    const leftovers = currentAllPanelGenerationKw - annualConsumption;
    setSoldAmount(anyToNumberConvert(leftovers));
    const income = leftovers * sellingPrice;
    setSellIncome(anyToNumberConvert(income));
  }, [
    panelAmount,
    annualConsumption,
    sellingPrice,
    solarData?.content?.data.pvOutAnnual,
    currentPanel?.specs.maxPower_W
  ]);

  useEffect(() => {
    let newOverallPrice = currentPanel?.price * panelAmount;
    if (isBatteryIncluded) {
      newOverallPrice *= (1 + batteryMultiplier);
    }
    setOverallPrice(anyToNumberConvert(newOverallPrice));
  }, [currentPanel?.price, panelAmount, isBatteryIncluded]);

  useEffect(() => {
    if (currentPanel?.price && annualBill && sellIncome >=0 && panelAmount > 0) {
      let newOverallPrice = currentPanel?.price * panelAmount;
      if (isBatteryIncluded) {
        newOverallPrice *= (1 + batteryMultiplier);
      }
      const newReturnTime = newOverallPrice / (annualBill + sellIncome);
      setReturnTime(newReturnTime);
    }
  }, [currentPanel, panelAmount, annualBill, sellIncome, isBatteryIncluded]);

  useEffect(() => {
    if (currentPanel?.price) {
      setAnimated(true);
      setTimeout(() => {
        setAnimated(false);
      }, 700);
    }
  }, [currentPanel]);

  // const isLoading = useMemo(() => {
  //   return !isSolarDataFetched || !isPanelsFetched;
  // }, [isSolarDataFetched, isPanelsFetched]);

  const handleToggleBattery = () => {
    setIsBatteryIncluded(!isBatteryIncluded)(dispatch);
  }

  const handleOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    setTimeout(() => {
      handleOpen();
    }, 100000);
  }, []);

  return (
    <Box sx={{
      paddingTop: {lg:"40px" , xs: "14px"},
      paddingLeft: {lg:"50px" , xs: "8px"},
      paddingRight: {lg:"50px" , xs: "8px"},
    }}>
      <Grid container>
        <Grid item xs={12}  md={3}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <IconButton
              aria-label="back"
              color="primary"
              sx={{ mr: 2, fontSize: 130 }}
              onClick={handleBack}
            >
              <ArrowBackIosNewOutlinedIcon sx={{ fontSize: 30 }}/>
            </IconButton>
            <Box>
              <Typography variant={"h4"}>
                {t("resultStep.solarQuote")}
              </Typography>
              <Typography variant={"body2"} color="primary" mb={2} mt={1}>
                {t("resultStep.thisIsEstimate")}
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={6} md={2}>
          <Typography variant={"body2"} color="primary">
            {t("price")}
          </Typography>
          <PanelTypography variant="h3" color="text.primary">
            <Highlight>{overallPrice.toLocaleString()} {currencySign}</Highlight>
          </PanelTypography>
        </Grid>
        <Grid item xs={6} md={2}>
          <Typography variant={"body2"} color="primary">
            {t("payback")}
          </Typography>
          <PanelTypography variant="h3" color="success.main">
            <Highlight>{returnTime.toFixed(2)}</Highlight> {t("yrs")}
          </PanelTypography>
        </Grid>
        <Grid item xs={12} md={5}>
          <Button
            variant={"contained"}
            color={"success"}
            sx={{ marginTop: {lg: 0, xs: "20px"} }}
            onClick={handleNext}
          >
            <CheckIcon sx={{ fontSize: 30, marginRight: "8px" }} />
            <PanelTypography variant="h3" color="white">
              {t("getOffer")}
            </PanelTypography>
          </Button>
        </Grid>
      </Grid>
      <Box sx={{height: "50px"}}/>
      <Grid container spacing={2}>
        <Grid item xs={12} md={5}>
          <DataRow mb={1}>
            <WbSunnyOutlinedIcon/>
            <Typography variant={"h4"} ml={1}>
              {t("resultStep.yourSystem")}
            </Typography>
          </DataRow>
          <StyledCard isAnimated={isAnimated}>
            <CardActionArea
              sx={{ display: "flex", flexDirection: "row", justifyContent: "start" }}
              onClick={() => openInfoSidebar("panels")}
            >
              <CardMedia
                component="img"
                sx={{ width: 151, height: 110 }}
                image="https://cdn.britannica.com/85/162485-050-7670426D/Solar-panel-array-rooftop.jpg"
                alt="solar panels"
              />
              <CardContent>
                <Typography gutterBottom variant="h4" component="div">
                  {currentPanel?.codeName ? currentPanel?.codeName : "Empty"}
                </Typography>
                <Typography variant="body2" color="text.primary" sx={{fontSize: '15px'}}>
                  {t("resultStep.youNeed")} <Highlight>{panelAmount} {t("resultStep.pcs")} </Highlight>
                </Typography>
                <Typography variant="body2" color="text.primary" sx={{fontSize: '11px'}}>
                  <Highlight>{t("resultStep.youCanAdjust")}</Highlight>
                </Typography>
              </CardContent>
            </CardActionArea>
          </StyledCard>

          <Box sx={{height: "20px"}}/>

          <StyledCard isAnimated={isAnimated}>
            <CardActionArea
              sx={{ display: "flex", flexDirection: "row", justifyContent: "start" }}
              onClick={() => openInfoSidebar("battery")}
            >
              <CardMedia
                component="img"
                sx={{ width: 151, height: 110 }}
                image="https://www.literoflightusa.org/wp-content/uploads/2021/02/How-to-Calculate-Solar-Panel-Battery-and-Inverter.jpg"
                alt="battery"
              />
              <CardContent>
                <Typography gutterBottom variant="h4" component="div">
                  {t("resultStep.battery")}
                </Typography>
               <Box
                onClick={(e) => {
                  e.stopPropagation();
                }}
               >
                 <FormControlLabel
                   sx={{
                     zIndex: "99999999"
                   }}
                   control=
                     {<Switch
                       checked={isBatteryIncluded}
                       onChange={handleToggleBattery}
                     />}
                   label={t("include")} />
               </Box>
              </CardContent>
            </CardActionArea>
          </StyledCard>

          <Box sx={{height: "20px"}}/>
          <StyledCard
            isAnimated={isAnimated}
            elevation={0}
            sx={{
              border: 'solid 2px',
              borderColor: lighten(theme.colors.success.main, 0.6)
            }}
          >
            <Box
              sx={{ display: "flex", flexDirection: "row", justifyContent: "start" }}
            >
              <CardMedia
                component="img"
                sx={{ width: 151, height: 110 }}
                image="https://cdn.britannica.com/62/96062-050-4AEC9BAD/collectors-roof-house-capture-solar-energy-air.jpg"
                alt="production"
              />
              <CardContent
                sx={{
                  mt: 1.5
                }}
              >
                <Typography gutterBottom variant="h4" component="div">
                  {t("resultStep.production")}
                </Typography>
                <Typography variant="body2" color="text.primary" sx={{fontSize: '20px'}}>
                  <Highlight>{annualGeneration.toFixed(2)} Kwh.</Highlight>
                </Typography>
              </CardContent>
            </Box>
          </StyledCard>

        </Grid>
        <Grid item xs={12} md={5}>
            <DataRow mb={1}>
              <CardGiftcardOutlinedIcon/>
              <Typography variant={"h4"} ml={1}>
                {t("resultStep.includedServices")}
              </Typography>
            </DataRow>
          <StyledCard isAnimated={isAnimated}>
            <CardActionArea
              sx={{ display: "flex", flexDirection: "row", justifyContent: "start" }}
              onClick={() => openInfoSidebar("installation")}
            >
              <CardMedia
                component="img"
                sx={{ width: 151, height: 110 }}
                image="https://www.hachettebookgroup.com/wp-content/uploads/2023/02/WEB-121_cHesh_InstallYourOwnSolarPanels.jpg"
                alt="installation"
              />
              <CardContent>
                <Typography gutterBottom variant="h4" component="div">
                  {t("resultStep.installationIncluded")}
                </Typography>
                <Typography variant="body2" color="primary" sx={{fontSize: '15px'}}>
                  {t("resultStep.readMore")}
                </Typography>
              </CardContent>
            </CardActionArea>
          </StyledCard>
          <Box sx={{height: "20px"}}/>
          <StyledCard isAnimated={isAnimated}>
            <CardActionArea
              sx={{ display: "flex", flexDirection: "row", justifyContent: "start" }}
              onClick={() => openInfoSidebar("warranty")}
            >
              <CardMedia
                component="img"
                sx={{ width: 151, height: 110 }}
                image="https://vakilsearch.com/blog/wp-content/uploads/2022/05/master-service-agreement-vs-statement-of-work.jpg"
                alt="Warranty"
              />
              <CardContent>
                <Typography gutterBottom variant="h4" component="div">
                  {t("resultStep.guarantee")}
                </Typography>
                <Typography variant="body2" color="primary" sx={{fontSize: '15px'}}>
                  <Highlight>
                    {t("resultStep.readMore")}
                  </Highlight>
                </Typography>
              </CardContent>
            </CardActionArea>
          </StyledCard>
          <Box sx={{height: "20px"}}/>
          <StyledCard isAnimated={isAnimated} sx={{ mb:1 }}>
            <CardActionArea
              sx={{ display: "flex", flexDirection: "row", justifyContent: "start" }}
              onClick={() => openInfoSidebar("chart")}
            >
              <CardMedia
                component="img"
                sx={{ width: 151, height: 110 }}
                image="https://vs-static.virtualspeech.com/img/blog/header/group-presentation-business.jpg"
                alt="Warranty"
              />
              <CardContent>
                <Typography gutterBottom variant="h4" component="div">
                  {t("resultStep.moreInfo")}
                </Typography>
                <Typography variant="body2" color="primary" sx={{fontSize: '15px'}}>
                  <Highlight>
                    {t("resultStep.readMore")}
                  </Highlight>
                </Typography>
              </CardContent>
            </CardActionArea>
          </StyledCard>
        </Grid>
      </Grid>
    </Box>
  );
};

interface CardAnimationProps {
  readonly isAnimated: boolean;
}

const StyledCard = styled(Card)<CardAnimationProps>`
  @keyframes ripple {
    0% {
      background: lightgray;
    }
    100% {
      background: white;
    }
  }
  animation: ${({ isAnimated }) => isAnimated ? "ripple 0.7s linear" : "none"};
`;

const PanelTypography = styled(Typography)`
  margin-top: 6px;
  flex: 0 0 auto;
`;

const Highlight = styled('span')(
  ({ theme }) => `
    color: ${theme.colors.primary.main}
`
);

export const DataRow = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  //justify-content: space-between;
`;
