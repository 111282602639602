import { Box, FormControlLabel, Switch, Typography } from '@mui/material';
import { styled } from '@mui/material';
import React from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks/storeHooks';
import { setIsBatteryIncluded } from '../../store/reducer/batterySlice';
import { useTranslation } from 'react-i18next';

export const BatteryInfo = () => {
  const isBatteryIncluded = useAppSelector(
    (state) => state.batterySlice.isBatteryIncluded
  );

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const handleToggleBattery = () => {
    setIsBatteryIncluded(!isBatteryIncluded)(dispatch);

  }

  return (
    <Box
      sx={{
        mt: 2,
        padding: 2
      }}
    >
      <Typography variant={'h3'} color={"text.primary"}>
        {t("resultStep.battery")}
      </Typography>
      <Box
        sx={{
          color: "text.primary"
        }}
      >
        <FormControlLabel control={<Switch checked={isBatteryIncluded} onChange={handleToggleBattery} />} label={t("include")} />
      </Box>
      <Typography
        variant={"body2"}
        color={"text.primary"}
        fontSize={"20px"}
        sx={{
          mt: 2
        }}
      >
        {t("resultStep.sidebar.batteryText")}
      </Typography>
      <StyledImage
        src='https://media.product.which.co.uk/prod/images/ar_2to1_1500x750/4843744cc307-2storage-battery-on-wall.jpg'
        alt=''

      />
    </Box>
  );
}

export const StyledImage = styled('img')`
  width: 100%;
  margin-top: 22px;
  border-radius: 8px;
`;
