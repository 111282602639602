import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { useEffect } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../hooks/storeHooks';
import { IUserLoginObject, loginToUser } from '../../../store/reducer/userSlice';
import { Container, styled } from '@mui/material';

const NegativeHighlight = styled('span')`
    color: lightgrey;
    font-weight: bold;
    //mix-blend-mode: difference;
    margin-right: 5px;
`

export const Copyright = (props: any) => {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      <NegativeHighlight>
        {'Copyright ©'}
      </NegativeHighlight>
      <Link color="inherit" href="https://envirio.online/">
        Envirio
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

type LocationState = { from: Location; };

const SignInSide = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const currentUser = useAppSelector((state) => state.user.data.content);

  useEffect(() => {
    if (currentUser) {
      const isRemember = localStorage.getItem("isEnvirioUserRemember") === "true";
      if (isRemember) {
        localStorage.setItem("currentEnvirioUser", JSON.stringify(currentUser));
      }
    }
  }, [currentUser]);

  const state = location.state as LocationState;
  const from = state?.from?.pathname || "/";

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const loginFormData = new FormData(event.currentTarget);
    const isRemember = !!loginFormData.get("isRemember");
    localStorage.setItem('isEnvirioUserRemember', String(isRemember));
    const loginObject = Object.fromEntries(loginFormData.entries()) as unknown;
    loginToUser(loginObject as IUserLoginObject)(dispatch);
  };

  return currentUser ? (
    <Navigate to={from} replace />
  ) : (
    <Grid container component="main" sx={{ height: '100vh' }}>
      <CssBaseline />
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          maxHeight: "100vh",
          // backgroundImage: 'url(https://source.unsplash.com/random)',
          backgroundRepeat: 'no-repeat',
          backgroundColor: (t) =>
            t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          overflow: "hidden"
        }}
      >
        <video
          height={"100%"}
          autoPlay
          muted
          loop
          id="loginVideo"
          src='https://video.wixstatic.com/video/28fa15_4822242f046f4b8da5bd0d5c7d32dfd3/1080p/mp4/file.mp4'
          />
      </Grid>
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <FormControlLabel
              control={<Checkbox name={"isRemember"} value="true" color="primary" />}
              label="Remember me"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="register" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
            <Container sx={{position: "absolute", bottom: "20px", right: "0"}}>
              <Copyright sx={{ mt: 5 }} />
            </Container>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}

export default SignInSide;
