import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import store, { AppDispatch } from "../index";
import { IRootSolarObject } from "../../types/solarTypes";
import { ContentLoading, initialContentLoading } from "../commonTypes";
import { client } from "../../api/AxiosInstance";

export interface ISolarData {
  data?: ContentLoading<null | IRootSolarObject>;
}

const initialState: ISolarData = { data: initialContentLoading(null) };

const solarSlice = createSlice({
  name: "solarSlice",
  initialState,
  reducers: {
    startGetSolar(state) {
      state.data = {
        content: null,
        status: "loading",
        error: undefined,
      };
    },
    setSolarData(state, action: PayloadAction<IRootSolarObject>) {
      state.data = {
        content: action.payload,
        status: "loaded",
        error: undefined,
      };
    },
    setError(state, action: PayloadAction<Error>) {
      state.data = {
        content: null,
        status: "error",
        error: action.payload,
      };
    },
  },
});

const { startGetSolar, setSolarData, setError } = solarSlice.actions;
export default solarSlice.reducer;

export const getSolarData =
  (lat: number, lon: number) => async (dispatch: AppDispatch) => {
    dispatch(startGetSolar());
    try {
      const solarData = await client.post<IRootSolarObject>("/api/fetchData", {
        lat,
        lon,
      });
      if (!solarData.data) {
        const err = new Error("Wrong response body");
        dispatch(setError(err));
      }
      dispatch(setSolarData(solarData.data));
    } catch (e) {
      if (typeof e === "string") {
        const err = new Error(e);
        dispatch(setError(err));
      } else if (e instanceof Error) {
        dispatch(setError(e));
      }
    }
  };
