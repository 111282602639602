import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppDispatch } from "../index";
import { ContentLoading, initialContentLoading } from "../commonTypes";
import { client } from "../../api/AxiosInstance";
import { IDistributor, IRootObject } from '../../types/panelTypes';
import { setPartialFilters } from './filterSlice';
import { mockDistributors } from '../../helpers/constants/mocks/distributors';

export interface IDistributorData {
  data?: ContentLoading<null | IDistributor[]>;
}

const initialState: IDistributorData = { data: initialContentLoading(null) };

const distributorSlice = createSlice({
  name: "distributorSlice",
  initialState,
  reducers: {
    startGetDistributors(state) {
      state.data = {
        content: null,
        status: "loading",
        error: undefined,
      };
    },
    setDistributorData(state, action: PayloadAction<IDistributor[]>) {
      state.data = {
        content: action.payload,
        status: "loaded",
        error: undefined,
      };
    },
    setError(state, action: PayloadAction<Error>) {
      state.data = {
        content: null,
        status: "error",
        error: action.payload,
      };
    },
  },
});

const { startGetDistributors, setDistributorData, setError } = distributorSlice.actions;
export default distributorSlice.reducer;

export const getDistributors =
  (lat: number, lon: number, countryCode: string) => async (dispatch: AppDispatch) => {
    dispatch(startGetDistributors());
    try {
      const setMocks = () => {
        dispatch(setDistributorData(mockDistributors));
        dispatch(setPartialFilters({
          distributorIds: mockDistributors.map((el) => el.id)
        }));
        return { distributors: mockDistributors, isMock: true };
      }

      const distributorData = await client.get<IRootObject<IDistributor[]>>(
        "/api/dist/getAllDistributorByCountryCode",
        { params: { countryCode: countryCode } }
      );

      if (!distributorData.data.data.length) {
        return setMocks()
      }
      dispatch(setDistributorData(distributorData.data.data));
      dispatch(setPartialFilters({
        distributorIds: distributorData.data.data.map((el) => el.id)
      }));
      return { distributors: distributorData.data.data, isMock: false };
    } catch (e) {
      if (typeof e === "string") {
        const err = new Error(e);
        dispatch(setError(err));
      } else if (e instanceof Error) {
        dispatch(setError(e));
      }
    }
  };
