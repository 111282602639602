import {
  AppBar,
  Box, CircularProgress,
  Drawer,
  Grid, IconButton,
  lighten,
  styled,
  Typography,
  useTheme
} from '@mui/material';
import { MainCurrentPanel } from '../../content/dashboards/Calculator/mainCalculator/mainCurrentPanel';
import { IStep } from './CoordinatesStep';
import React, { FC, useEffect, useState } from 'react';
import { Panelnfo } from '../InfoSidebarContent/Panelnfo';
import { InstallationInfo } from '../InfoSidebarContent/InstallationInfo';
import { WarrantyInfo } from '../InfoSidebarContent/WarrantyInfo';
import { BatteryInfo } from '../InfoSidebarContent/BatteryInfo';
import { useAppSelector } from '../../hooks/storeHooks';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { ChartInfo } from '../InfoSidebarContent/ChartInfo';

export type infoSidebarContentType = "panels" | "warranty" | "installation" | "battery" | "chart";

export const ResultStep: FC<IStep> = ({ setNextStepAvailable, handleNext, handleBack }) => {
  const [infoSidebarToggle, setInfoSidebarToggle] = useState<boolean>(false);
  const [sidebarType, setSidebarType] = useState<null | infoSidebarContentType>(null);
  const [isLoading, setIsLoading] = useState(true);
  const theme = useTheme();

  const { t } = useTranslation();

  const electricData = useAppSelector((state) => state.electric.data);
  const currencySign = electricData?.content?.currencySign;

  const lifetimeValue = electricData?.content?.bills.annualBill * 25;

  const closeInfoSidebar = () => {
    setInfoSidebarToggle(false);
  }

  const content: Record<infoSidebarContentType, JSX.Element> = {
    'installation' : <InstallationInfo/>,
    'panels' : <Panelnfo />,
    'warranty': <WarrantyInfo/>,
    'battery': <BatteryInfo/>,
    'chart': <ChartInfo/>
  }

  const openInfoSidebar = (type: infoSidebarContentType) => {
    setSidebarType(type);
    setInfoSidebarToggle(true);
  }

  useEffect(() => {
    setNextStepAvailable(4);
  }, []);

  useEffect(() => {
    const timeoutTime = Math.floor(Math.random() * 1000) + 1000;
    setTimeout(() => {
      setIsLoading(false);
    }, timeoutTime);
  }, []);

  return (
    isLoading
      ? <Box sx={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <Typography variant={"h4"} fontSize={"20px"} sx={{ mb: 3 }}>
            {t("Loading your results...")}
          </Typography>
          <CircularProgress size={120} disableShrink thickness={3}/>
        </Box>
      </Box>
      : <Box
      sx={{
        backgroundColor: lighten(theme.colors.primary.main, 0.95),
      }}
    >
      <Drawer
        sx={{
          boxShadow: `${theme.sidebar.boxShadow}`
        }}
        anchor={'right'}
        open={infoSidebarToggle}
        onClose={closeInfoSidebar}
        variant="temporary"
        ModalProps={{
          keepMounted: false,
        }}
        elevation={9}
      >
        <SidebarWrapper
          sx={{
            background: theme.colors.alpha.white[100],
            padding: 1,
            backgroundColor: 'whitesmoke',
            width: {xs: "100vw", lg: "50vw"}
          }}
        >
          <AppBar sx={{ position: 'absolute', height: "70px", display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <IconButton
              onClick={closeInfoSidebar}
              color="inherit"
              sx={{
                margin: 1,
              }}
            >
              <CloseIcon />
            </IconButton>
            <Typography variant={"h4"} fontSize={"20px"}
              sx={{
                ml: 2,
                fontSize: {lg: "20px", xs: "14px"}
              }}
            >
              {t("resultStep.sidebar.header")}: {`${lifetimeValue.toLocaleString()} ${currencySign}`}
            </Typography>
          </AppBar>
          <Box
            sx={{
              mt: "70px"
            }}
          >
            {content[`${sidebarType}`]}
          </Box>
        </SidebarWrapper>
      </Drawer>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <MainCurrentPanel openInfoSidebar={openInfoSidebar} handleNext={handleNext} handleBack={handleBack} />
        </Grid>
      </Grid>
    </Box>
  );
}

const SidebarWrapper = styled(Box)(
  ({ theme }) => `
        width: 500px;
        min-width: ${theme.sidebar.width};
        color: ${theme.colors.alpha.trueWhite[70]};
        position: relative;
        z-index: 7;
        height: 100%;
        padding-bottom: 68px;
`
);
