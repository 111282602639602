import { useMemo, useRef, useState } from 'react';
import { Marker, useMapEvents } from 'react-leaflet';

export interface IMapCoords {
  lat: number;
  lng: number;
}

interface IDraggableMarkerProps {
  center: IMapCoords;
  onChangeMarkerPosition: (newPosition: IMapCoords) => void
}

export const DraggableMarker = ({center, onChangeMarkerPosition}: IDraggableMarkerProps) => {
  const [draggable, setDraggable] = useState(true);
  const [position, setPosition] = useState(center);
  const markerRef = useRef(null);

  const eventHandlers = useMemo(
    () => ({
      dragend() {
        const marker = markerRef.current;
        if (marker != null) {
          setPosition(marker.getLatLng())
          onChangeMarkerPosition(marker.getLatLng());
        }
      },
    }),
    [],
  );

  const map = useMapEvents({
    click(e) {
      setPosition(e.latlng);
      onChangeMarkerPosition(e.latlng)
    },
  })

  // const toggleDraggable = useCallback(() => {
  //   setDraggable((d) => !d)
  // }, [])

  return (
    <Marker
      draggable={draggable}
      eventHandlers={eventHandlers}
      position={position}
      ref={markerRef}>
      {/*<Popup minWidth={90}>*/}
      {/*  <Button onClick={toggleDraggable}>*/}
      {/*    {draggable*/}
      {/*      ? 'Marker is draggable'*/}
      {/*      : 'Click here to make marker draggable'}*/}
      {/*  </Button>*/}
      {/*</Popup>*/}
    </Marker>
  )
}
