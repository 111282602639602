import React, { FC, useCallback, useEffect, useState } from 'react';
import {
  Box,
  Card,
  debounce, Grid,
  InputAdornment,
  styled,
  TextField, Typography
} from '@mui/material';
import equal from 'fast-deep-equal';
import { useAppDispatch, useAppSelector } from '../../../hooks/storeHooks';
import { updateConsumption } from '../../../store/reducer/electricSlice';
import { IConsumptionData } from '../../../helpers/constants/consumption';
import {
  IStringedData,
  objectPropertiesToNumber,
  objectPropertiesToString
} from '../../../helpers/transformations/objectTransformations';
import {
  getAnnualConsumptionByMonthlyBill,
  getMonthlyBillByConsumption,
  IBillToRecalculateConsumption,
  IConsumptionToRecalculateMonthly
} from '../../../helpers/calcHelpers';
import { isMobile } from '../../../helpers/isMobileHelper';
import { useTranslation } from 'react-i18next';

export const BillInput: FC = () => {
  const [inputData, setInputData] = useState<IStringedData<IConsumptionData> | null>(null)

  const { t } = useTranslation();

  const electricData = useAppSelector((state) => state.electric.data);

  const dispatch = useAppDispatch();
  const currencySign = electricData?.content?.currencySign;
  // const isSeparated = featureConfig.type === ConfigTypes.iframeFeatureConfig;

  useEffect(() => {
    if (electricData?.content?.consumption && !inputData) {
      const stringInputData = objectPropertiesToString<IConsumptionData>(electricData.content.consumption);
      if (!equal(stringInputData, inputData)) {
        setInputData(stringInputData);
      }
    }
  }, [electricData?.content?.consumption]);

  useEffect(() => {
    console.log("electricData?.content?.consumption", electricData?.content?.consumption);
  }, [electricData?.content?.consumption]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = e;
    const newInputData = {
      ...inputData,
      [e.target.name]: value
    };
    if (e.target.name === "monthlyConsumption") {
      const normalizedValue = ((parseFloat(value)*12) || 0).toString();
      const objectToRecalculate = objectPropertiesToNumber<IStringedData<IConsumptionToRecalculateMonthly>>({
        annualConsumption: normalizedValue,
        electricityPrice: inputData?.electricityPrice
      });
      newInputData.annualConsumption = normalizedValue;
      newInputData.monthlyBill =
        getMonthlyBillByConsumption(objectToRecalculate).toFixed(2).toString();
    }
    if (e.target.name === "annualConsumption") {
      const objectToRecalculate = objectPropertiesToNumber<IStringedData<IConsumptionToRecalculateMonthly>>({
        annualConsumption: value,
        electricityPrice: inputData?.electricityPrice
      });
      newInputData.monthlyBill =
        getMonthlyBillByConsumption(objectToRecalculate).toFixed(2).toString();
    }
    if (e.target.name === "monthlyBill") {
      const objectToRecalculate = objectPropertiesToNumber<IStringedData<IBillToRecalculateConsumption>>({
        monthlyBill: value,
        electricityPrice: inputData?.electricityPrice
      });
      newInputData.annualConsumption =
        getAnnualConsumptionByMonthlyBill(objectToRecalculate).toFixed(2).toString();
    }
    if (e.target.name === "electricityPrice") {
      const objectToRecalculate = objectPropertiesToNumber<IStringedData<IBillToRecalculateConsumption>>({
        monthlyBill: inputData?.monthlyBill ,
        electricityPrice: value
      });
      newInputData.annualConsumption =
        getAnnualConsumptionByMonthlyBill(objectToRecalculate).toFixed(2).toString();
    }
    setInputData(newInputData);
    debouncedUpdateBills(
      newInputData
    );
  };

  const updateBills = (data: IStringedData<IConsumptionData>) => {
    const numberedData = objectPropertiesToNumber(data);
    updateConsumption(numberedData)(dispatch);
  };

  const debouncedUpdateBills = useCallback(debounce(updateBills, 700), []);
  const isDisabled = electricData?.content?.consumption?.annualConsumption === undefined;
  return (
    <ElectricalDataCard
      sx={{
        width: { xs: "90%", md: "60%", lg: "50%" }
      }}
    >
      <Grid container>
        <Grid xs={12} md={12}>
          <Card
            sx={{
              padding: "20px 20px"
            }}
          >
            <Box
              sx={{
                marginTop: "8px",
                marginLeft: "8px",
                marginBottom: "12px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center"
              }}
            >
              <AnimatedHint variant={isMobile() ? "h5" : "h3"}>
                {t("monthlyBillStep.monthlyBillTooltip")}
              </AnimatedHint>
              {/*<Tooltip arrow placement={"top"} title={t("monthlyBillStep.monthlyBillTooltip")}>*/}
              {/*  <InfoOutlinedIcon fontSize={"medium"} sx={{ml:1}}/>*/}
              {/*</Tooltip>*/}
            </Box>
            <TextField
              disabled={isDisabled}
              name={"monthlyBill"}
              // label="Monthly Bill"
              value={inputData?.monthlyBill}
              sx={{ width: "100%" }}
              onChange={handleChange}
              InputLabelProps={{style: {fontSize: 24}}}
              InputProps={{
                style: {fontSize: 24},
                startAdornment: (
                  <InputAdornment position="start">{currencySign}</InputAdornment>
                ),
              }}
            />
          </Card>
        </Grid>
      </Grid>

    </ElectricalDataCard>
  );
};

const ElectricalDataCard = styled(Box)`
  
`;

const AnimatedHint = styled(Typography)`
  @keyframes flashing {
    0% {
      color: ${({theme}) => theme.colors.primary.main};
    }
    20% {
      color: ${({theme}) => theme.colors.primary.lighter};
    }
    40% {
      color: ${({theme}) => theme.colors.primary.main};
    }
    60% {
      color: ${({theme}) => theme.colors.primary.lighter};
    }
    80% {
      color: ${({theme}) => theme.colors.primary.main};
    }
    90% {
      color: ${({theme}) => theme.colors.primary.lighter};
    }
    100% {
      color: ${({theme}) => theme.colors.primary.main};
    }
  }
  color: ${({theme}) => theme.colors.primary.main};
  //animation: flashing 2.5s linear;
`;
