import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppDispatch } from "../index";
import { IPanel } from "../../types/panelTypes";

export interface IPanelData {
  data?: IPanel;
}

const initialState: IPanelData = { data: undefined };

const currentPanelSlice = createSlice({
  name: "currentPanelSlice",
  initialState,
  reducers: {
    setCurrentPanelData(state, action: PayloadAction<IPanel>) {
      state.data = action.payload;
    },
  },
});

const { setCurrentPanelData } = currentPanelSlice.actions;
export default currentPanelSlice.reducer;

export const setCurrentPanel = (panel: IPanel) => (dispatch: AppDispatch) => {
  dispatch(setCurrentPanelData(panel));
};
