import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch } from '../index';
import { ContentLoading, initialContentLoading } from '../commonTypes';
import { IPanel, IRootPanelData } from '../../types/panelTypes';
import { CountrySpecificMocks, defaultMockPanels } from '../../helpers/constants/mocks/panels';
import { testUrlForMainPage, testUrlForMock } from '../../helpers/isMockHelper';
import countryToCurrency from "country-to-currency";
import { Currencies } from '../../helpers/constants/mocks/currencies';

export interface IPanelData {
  data?: ContentLoading<null | IRootPanelData>;
}

const initialState: IPanelData = { data: initialContentLoading(null) };

const panelSlice = createSlice({
  name: "panelSlice",
  initialState,
  reducers: {
    startGetPanel(state) {
      state.data = {
        content: null,
        status: "loading",
        error: undefined,
      };
    },
    setPanelData(state, action: PayloadAction<IRootPanelData>) {
      state.data = {
        content: action.payload,
        status: "loaded",
        error: undefined,
      };
    },
    setError(state, action: PayloadAction<Error>) {
      state.data = {
        content: null,
        status: "error",
        error: action.payload,
      };
    },
  },
});

const { startGetPanel, setPanelData, setError } = panelSlice.actions;
export default panelSlice.reducer;

export const getPanelDataByCurrentUrl = (countryCode: string, currencies: Currencies) => async (dispatch: AppDispatch) => {
  dispatch(startGetPanel());
  try {
    // const url = getOutsideUrl();

    // TODO uncomment in case of iframe will be placed on other website
    // const panels = await client.get<IRootPanelData>(
    //   "/api/egc/getCompanyEgc",
    //   {
    //     headers: {
    //       'Company-Url': testUrlForLocalhost() ? "https://envirio.me" : url,
    //       'Content-Type' : 'multipart/form-data'
    //     }
    //   }
    // );
    const convertedCurrencyMocks = await convertMocks(defaultMockPanels, countryCode, currencies);
    const panelData: IRootPanelData = {
      meta: "ok",
      data: convertedCurrencyMocks
    }
    dispatch(setPanelData(panelData));
    // if (panels?.data?.data?.length) {
    //   dispatch(setPanelData(panels.data));
    // } else {
    //   const err = new Error("Wrong response body");
    //   const panelData: IRootPanelData = {
    //     timestamp: new Date(),
    //     meta: "ok",
    //     data: []
    //   }
    //   panelData.data = CountrySpecificMocks.get("DF");
    //   dispatch(setPanelData(panelData))
    //   dispatch(setError(err));
    // }
  } catch (e) {
    if (typeof e === "string") {
      const err = new Error(e);
      dispatch(setError(err));
    } else if (e instanceof Error) {
      dispatch(setError(e));
    }
  }
}

export const getPanelDataByCountry =
  (lat: number, lon: number, countryCode: string, currencies: Currencies) => async (dispatch: AppDispatch) => {
  dispatch(startGetPanel());
  try {
    // const { distributors, isMock } = await getDistributors(lat, lon, countryCode)(dispatch);
    // const promiseArray: Promise<AxiosResponse<IRootPanelData, any>>[] = [];

    // if (isMock) {
    const panelData: IRootPanelData = {
      meta: "ok",
      data: []
    }
    panelData.data = await convertMocks(CountrySpecificMocks.get("DF"), countryCode, currencies);

    dispatch(setPanelData(panelData));
    // TODO delete if no need in seller panel kits
    // } else {
    //   distributors.forEach((distributor) => {
    //     const panelPromise = client.get<IRootPanelData>(
    //       "/api/egc/getAllEgc",
    //       { params: { id: distributor.id } }
    //     );
    //     promiseArray.push(panelPromise);
    //   });
    //
    //   Promise.all(promiseArray).then((values) => {
    //     const allPanels: IPanel[] = [];
    //     let timestamp;
    //     values.forEach((panelValue, index) => {
    //       const currentPanels = panelValue.data.data.map((panel) => ({...panel, distributor: distributors[index]}));
    //       allPanels.push(...currentPanels);
    //       if (index === values.length - 1) {
    //         timestamp = panelValue.data.timestamp;
    //       }
    //     });
    //
    //     if (!allPanels.length) {
    //       const err = new Error("Wrong response body");
    //       dispatch(setError(err));
    //     }
    //
    //     const panelData: IRootPanelData = {
    //       timestamp: timestamp,
    //       meta: "ok",
    //       data: allPanels
    //     }
    //     dispatch(setPanelData(panelData));
    //   });
    // }
  } catch (e) {
    if (typeof e === "string") {
      const err = new Error(e);
      dispatch(setError(err));
    } else if (e instanceof Error) {
      dispatch(setError(e));
    }
  }
}

export const getPanelData =
  (lat: number, lon: number, countryCode: string, currencies: Currencies) => async (dispatch: AppDispatch) => {
    dispatch(startGetPanel());
    try {
      if (testUrlForMainPage() || testUrlForMock()) {
        await getPanelDataByCountry(lat, lon, countryCode, currencies)(dispatch);
      } else {
        await getPanelDataByCurrentUrl(countryCode, currencies)(dispatch);
      }
    } catch (e) {
      if (typeof e === "string") {
        const err = new Error(e);
        dispatch(setError(err));
      } else if (e instanceof Error) {
        dispatch(setError(e));
      }
    }
  };

const countryMultipliers = {
  MD: 1,
  UA: 1,
  RO: 1.3,
  DE: 1.6,
  DF: 1.5,
  US: 1.8,
}

const convertMocks = async (sourceArray: IPanel[], countryCode: string, currencyJson: Currencies) => {
  const convertedCurrencyMocks: IPanel[] = sourceArray.map((el) => {
    let fromRate = currencyJson.rates[el.currency];
    const currencyToConvert = countryToCurrency[countryCode];
    let toRate = currencyJson.rates[currencyToConvert];
    console.log("TO RATE", toRate);
    console.log("FROM RATE", fromRate);
    const multiplier = countryMultipliers[countryCode] || countryMultipliers["DF"];
    const resultPrice =
      ((toRate / fromRate) * el.price * multiplier);
    return {...el, price: Math.round(resultPrice)}
  });
  return convertedCurrencyMocks;
}
