import { PanelData } from '../../content/dashboards/Calculator/panelData';
import { useAppSelector } from '../../hooks/storeHooks';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const Panelnfo = () => {
  const currentPanel = useAppSelector((state) => state.currentPanel.data);
  const { t } = useTranslation();

  return (
    <>
      <PanelData/>
      <Box
        sx={{
          padding: 2,
          mt: 2
        }}
      >
        <Typography variant={"h3"}>
          {currentPanel.codeName}
        </Typography>
        <Typography
          variant={"body1"}
          sx={{ mt: 2 }}
          color={"text.primary"}
          fontSize={"20px"}
        >
          {t(`resultStep.sidebar.${currentPanel.description}`)}
        </Typography>
      </Box>
    </>
  );
}
