import React, { useEffect, useMemo, useState } from 'react';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LANGUAGES_AVAILABLE } from '../i18n/config';
import { ConfigTypes } from '../featureConfig';
import { useAppSelector } from '../hooks/storeHooks';

const iframeForbiddenLanguages = ["ru"];

export const LanguageToggle= () => {
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState("");
  const featureConfig = useAppSelector((state) => state.config.data);


  const isIframe = featureConfig.type === ConfigTypes.iframeFeatureConfig;

  const languagesToChoose = useMemo(() => {
    if (isIframe) {
      return LANGUAGES_AVAILABLE.filter((el) => {
        return !iframeForbiddenLanguages.includes(el);
      });
    } else {
      return LANGUAGES_AVAILABLE;
    }
  }, [isIframe]);

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newLanguage: string,
  ) => {
    setLanguage(newLanguage);
    i18n.changeLanguage(newLanguage);
  };

  useEffect(() => {
    setLanguage(i18n.language);
  }, [i18n.language]);

  return (
    <ToggleButtonGroup
      color="primary"
      value={language}
      exclusive
      onChange={handleChange}
      aria-label="Platform"
    >
      {languagesToChoose.map((el) =>
        <ToggleButton key={el} value={el}>{el.toUpperCase()}</ToggleButton>
      )}
    </ToggleButtonGroup>
  );
}
