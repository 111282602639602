import {
  IConsumptionData,
} from "./constants/consumption";
import { IBills } from "../store/reducer/electricSlice";

const CO2_RATIO = 229;

export type IConsumptionToRecalculateMonthly = Omit<IConsumptionData, "monthlyBill" | "electricitySellingPrice">;


export const getAnnualBillByConsumption = ({ annualConsumption, electricityPrice } : IConsumptionToRecalculateMonthly) => {
  return annualConsumption * electricityPrice;
}

export const getMonthlyBillByConsumption = ({ annualConsumption, electricityPrice } : IConsumptionToRecalculateMonthly) => {
  const annualBill = getAnnualBillByConsumption({ annualConsumption, electricityPrice });
  return annualBill / 12;
}

export type IBillToRecalculateConsumption = Omit<IConsumptionData, "annualConsumption" | "electricitySellingPrice">;

export const getAnnualConsumptionByMonthlyBill = ({ monthlyBill, electricityPrice }: IBillToRecalculateConsumption) => {
  const annualBill = monthlyBill * 12;
  const rawAnnualConsumption = annualBill / electricityPrice;
  return Number.isFinite(rawAnnualConsumption) ? rawAnnualConsumption : 0;
}

export const calculateBills = (consumption: IConsumptionData): IBills => {
  const annualBill = getAnnualBillByConsumption({
      annualConsumption: consumption.annualConsumption,
      electricityPrice: consumption.electricityPrice
    });
  const monthlyBill = annualBill / 12;
  const annualCo2 = (consumption.annualConsumption * CO2_RATIO) / 1000;
  const monthlyCo2 = annualCo2 / 12;
  return { annualBill, monthlyBill, annualCo2, monthlyCo2 };
};
