// import { countryDictionary } from './constants/dictionaries/countryDictionary';
// import { findCountryByCountryName } from './constants/dictionaries/countryCodes';
import { client } from '../api/AxiosInstance';
import { IDataIpObject, IRootObject } from '../types/panelTypes';

// export const CountryFromTimezoneHelper = () => {
//   const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
//   const tzArr = userTimeZone.split("/");
//   const userRegion = tzArr[0];
//   const userCity = tzArr[tzArr.length - 1];
//   const userCountry = countryDictionary[userCity];
//   const countryCode = findCountryByCountryName(userCountry).code.toUpperCase();
//   return {
//     countryCode,
//     country: userCountry,
//     region: userRegion,
//     city: userCity,
//     timezone: userTimeZone
//   };
// }

export const CountryFromTimezoneHelper = async () => {
  const res = await client.get<IRootObject<IDataIpObject>>(
    "/support/ip",
  );
  const ipData = res.data.data;
  return {
    countryCode: ipData.countryCode,
    country: ipData.countryName,
    // region: ipData.region,
    // city: ipData.city,
    // timezone: ipData.timezone
  };

}
