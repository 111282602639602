import { MainCoordsBlock } from '../../content/dashboards/Calculator/mainCalculator/mainCoordsBlock';
import React, { FC, useEffect } from 'react';
import { Box, Card, Grid, lighten, styled, Theme, Typography } from '@mui/material';
import { ICountryData, setCoordinates, setCountryData } from '../../store/reducer/electricSlice';
import { CountryFromTimezoneHelper } from '../../helpers/countryFromTimezoneHelper';
import { CoordinatesMock } from '../../helpers/constants/mocks/coordinates';
import { Countries } from '../../helpers/constants/countries';
import { useAppDispatch, useAppSelector } from '../../hooks/storeHooks';
import { setCurrentPanel } from '../../store/reducer/currentPanelSlice';
import { singleMockPanel } from '../../helpers/constants/mocks/panels';
import { PropertyTypeRadioGroup } from '../PropertyTypeRadioGroup';
import { isMobile } from '../../helpers/isMobileHelper';
import { Trans } from 'react-i18next';

export interface IStep {
  setNextStepAvailable?: (step: number) => void;
  handleNext?: () => void;
  handleBack?: () => void;
}

export const CoordinatesStep: FC<IStep> = ({ setNextStepAvailable }) => {
  const featureConfig = useAppSelector((state) => state.config.data);
  const propertyType = useAppSelector((state) => state.propertyType.data);
  const isNextStepVisited = useAppSelector((state) => state.stepSlice.data.find((el) => el === 1));
  const countryDataFromStore = useAppSelector((state) => state.electric?.data?.content?.country);

  const dispatch = useAppDispatch();

  // useEffect(() => {
  //   void checkGeoAvailable();
  // }, []);

  useEffect(() => {
    fetchCountryData();
  }, [propertyType]);

  // const checkGeoAvailable = async () => {
  //   const permissionStatus = await navigator?.permissions?.query({name: 'geolocation'});
  //   const isAvailable = permissionStatus?.state !== "denied";
  //   setGeoWorking(isAvailable);
  // }

  // const handleClick = () => {
  //   if (error)
  //     setError(null);
  //   if (isGeoWorking) {
  //     retryCountCoordinates.current = 5;
  //     getCoordinates();
  //   } else {
  //     retryCountCoordinates.current = 0;
  //     const error = new Error("Geoposition is not available");
  //     coordinatesFail(error);
  //   }
  // };

  // const debouncedHandleClick = useCallback(debounce(handleClick, 700), []);

  // const getCoordinates = () => {
  //   try {
  //     navigator.geolocation.getCurrentPosition(
  //       coordinatesSuccess,
  //       coordinatesFail,
  //       {
  //         maximumAge: 1000 * 60 * 60,
  //         timeout: 2000
  //       }
  //     );
  //   } catch {
  //     setError("Unable to get coordinates");
  //   }
  // }

  // const coordinatesSuccess = (coordinates: GeolocationPosition) => {
  //   // fetchCountryData();
  //   const lat = coordinates.coords.latitude;
  //   const lon = coordinates.coords.longitude;
  //   setCoordinates({lat, lon})(dispatch);
  //   setNextStepAvailable(1);
  // }
  //
  // const coordinatesFail = async (_: GeolocationPositionError | Error) => {
  //   // fetchCountryData();
  //   if (retryCountCoordinates.current > 0) {
  //     retryCountCoordinates.current -= 1;
  //     getCoordinates();
  //   } else {
  //     const timezoneCountryData = await CountryFromTimezoneHelper();
  //     const countryCode = timezoneCountryData.countryCode;
  //     let mockCoords = CoordinatesMock.get(countryCode as Countries);
  //     if (!mockCoords) {
  //       mockCoords = CoordinatesMock.get("DF");
  //     }
  //     setCoordinates(mockCoords)(dispatch);
  //     setNextStepAvailable(1);
  //     //setError(e.message);
  //   }
  // }

  const fetchCountryData = async () => {
    let countryData;
    if (!isNextStepVisited) {
      countryData = await CountryFromTimezoneHelper();
    } else {
      countryData = countryDataFromStore;
    }
    // TODO change it case to test different country
    // countryData.countryCode = "BR";

    if (!featureConfig.panelData) {
      setCurrentPanel(singleMockPanel)(dispatch);
    }
    await setMockCoordinates(countryData);
  };

  const setMockCoordinates = async (countryData: ICountryData) => {
    let mockCoords = CoordinatesMock.get(countryData.countryCode as Countries);
    if (!mockCoords) {
      mockCoords = CoordinatesMock.get("DF");
    }
    const isBusiness = propertyType === "business";
    await setCountryData(countryData, isBusiness, mockCoords, dispatch);
    setCoordinates(mockCoords)(dispatch);
    setNextStepAvailable(1);
  }

  return (
    <Wrapper
      sx={{
        marginTop: { xs: "10px", md: "20px", lg: "60px" }
      }}
    >
      <StyledHeader variant={ isMobile() ? 'h4' :'h3'}>
        <Trans i18nKey={"coordinatesStep.calculateProfitability"} components={{ 1: <br /> }}/>
      </StyledHeader>
      <StyledCard
        sx={{
          width: isMobile() ? "100%" : "50%",
          backgroundColor: (theme: Theme) =>
            lighten(theme.colors.primary.main, 0.93)
        }}>
        <Grid container>
          <Grid item xs={12} md={6}>
            <PropertyTypeRadioGroup />
          </Grid>
          {/*<Grid xs={12} md={6}>*/}
          {/*  <PropertyHint*/}
          {/*    animated={!propertyType}*/}
          {/*    variant={"h4"}*/}
          {/*    sx={{*/}
          {/*      marginTop: {lg: "40px", xs: "20px"}*/}
          {/*    }}*/}
          {/*  >*/}
          {/*    Choose your property type*/}
          {/*  </PropertyHint>*/}
          {/*</Grid>*/}
          <Grid item xs={12}>
            <Box sx={{height: "30px"}}/>
          </Grid>
          <Grid item xs={12}>
            <MainCoordsBlock setCoordinatesByCountry={setMockCoordinates}/>
          </Grid>
        </Grid>
      </StyledCard>
    </Wrapper>
  );
}

const StyledCard = styled(Card)`
  padding: 12px;
  margin-top: 20px;
`;

const Wrapper = styled("div")`
  height: 70vh;
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const StyledHeader = styled(Typography)`
  text-align: center;
`;
