import { CircularProgress, styled, Typography } from '@mui/material';
import React from 'react';

export const EnviIsWritingMessage = () => {
  return (
    <Row>
      <CircularProgress size={10} thickness={2} />
      <Typography variant="subtitle2" sx={{marginLeft: "8px"}}>
        Envi is writing...
      </Typography>
    </Row>
  );
}

const Row = styled("div")`
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
