export interface IFeatureConfig {
  coordinates: boolean;
  dataInputs: boolean;
  annualStats: boolean;
  visitDistributor: boolean;
  currentPanel: boolean;
  additionalInfo: boolean;
  panelData: boolean;
  sidebar: boolean;
  topBar: boolean;
  filters: boolean;
  type: ConfigTypes;
}

export enum ConfigTypes {
  default,
  iframeFeatureConfig
}

export const defaultFeatureConfig: IFeatureConfig = {
  coordinates: true,
  dataInputs: true,
  annualStats: true,
  visitDistributor: true,
  currentPanel: true,
  additionalInfo: true,
  panelData: true,
  sidebar: true,
  topBar: true,
  filters: true,
  type: ConfigTypes.default
};

export const iframeFeatureConfig: IFeatureConfig = {
  coordinates: true,
  dataInputs: true,
  annualStats: true,
  visitDistributor: false,
  currentPanel: true,
  additionalInfo: false,
  panelData: true,
  sidebar: false,
  topBar: false,
  filters: true,
  type: ConfigTypes.iframeFeatureConfig
};

export const featureConfigs = {
  defaultFeatureConfig,
  iframeFeatureConfig
}
