import { Box , Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { AdditionalInfo } from '../../content/dashboards/Calculator/additionalInfo';

export const ChartInfo = () => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        mt: 2,
        padding: 2
      }}
    >
      <Typography variant={'h3'} color={"text.primary"}>
        {t("resultStep.moreInfo")}
      </Typography>
      <AdditionalInfo/>
      <Typography
        variant={"body2"}
        color={"text.primary"}
        fontSize={"20px"}
        sx={{
          mt: 2
        }}
      >
        {t("resultStep.sidebar.chartText")}
      </Typography>
    </Box>
  );
}
