import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./reducer";
import {configureStore} from "@reduxjs/toolkit";

const initialState = {};

const store = configureStore({
    reducer: rootReducer,
    preloadedState: initialState
});
export type AppDispatch = typeof store.dispatch;

export default store;
