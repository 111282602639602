import { Countries } from "./countries";

export interface IConsumptionData {
  annualConsumption: number;
  monthlyBill: number;
  electricityPrice: number;
  electricitySellingPrice: number;
}

export const consumptionByCountry: Record<Countries, IConsumptionData> = {
  "MD":
  {
    annualConsumption: 2000,
      monthlyBill: 600,
    electricityPrice: 0.078,
    electricitySellingPrice: 2.28,
  },
  "RO":
  {
    annualConsumption: 3000,
      monthlyBill: 500,
    electricityPrice: 3,
    electricitySellingPrice: 2.28,
  },
  "DF":
  {
    annualConsumption: 4000,
      monthlyBill: 66.6,
    electricityPrice: 0.2,
    electricitySellingPrice: 0.2,
  },
};
