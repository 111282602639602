import React, { FC, useEffect, useState } from 'react';
import { IStep } from './CoordinatesStep';
import { Box, Button, Card, Grid, InputAdornment, TextField, Typography } from '@mui/material';
import { isMobile } from '../../helpers/isMobileHelper';
import { useAppDispatch, useAppSelector } from '../../hooks/storeHooks';
import { setUserEmail, setUserName, setUserPhone } from '../../store/reducer/contactDataSlice';
import { ConfigTypes } from '../../featureConfig';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// const TelegramBotToken = "6945933615:AAGBrfSO-OC6Brczc4lMm6aZFxIdfDc4dao";

export const ContactStep: FC<IStep> = ({setNextStepAvailable}) => {
  const [value, setValue] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [email, setEmail] = useState<string>('');

  const navigate = useNavigate();

  const { t } = useTranslation();

  const contactData = useAppSelector((state) => state.contactDataSlice);
  const featureConfig = useAppSelector((state) => state.config.data);

  const dispatch = useAppDispatch();

  const handleChange = (e) => {
    const newVal = e.target.value;
    setValue(newVal);
    setUserName(newVal)(dispatch);
  }

  const handleChangePhone = (e) => {
    const phoneRegex = new RegExp(/\d/g);

    const newPhone = e.target.value;
    if (newPhone.length) {
      const lastEl = newPhone[newPhone.length - 1];
      if (phoneRegex.test(lastEl)) {
        setPhone(newPhone);
        setUserPhone(newPhone)(dispatch);
      }
    } else {
      setPhone(newPhone);
      setUserPhone(newPhone)(dispatch);
    }
  }

  const handleChangeEmail = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    setUserEmail(newEmail)(dispatch);
  }

  useEffect(() => {
    const isNameEligible = value.length > 3;
    const isPhoneEligible = phone.length > 5;

    if (isNameEligible && isPhoneEligible) {
      setNextStepAvailable(5);
    }
  }, [value, phone, email]);

  const handleReload = () => {
    const isIframe = featureConfig.type === ConfigTypes.iframeFeatureConfig;
    if (isIframe) {
      navigate('/dashboards/calculator');
    } else {
      location.reload();
    }
  }

  return (
   <Box
    sx={{
      mt: 2,
      mb: 2,
      display: 'flex',
      alignItems: "center",
      justifyContent: "center",
      height: "70vh"
    }}
   >
     { contactData.status === "init" ?  <Card
       sx={{
         padding: 2,
         height: '100%',
         width: { xs: '90%', lg: '600px' }
       }}
     >
       <Typography variant={'h3'}>
         {t("getOffer")}
       </Typography>
       <Grid xs={12} md={12}>
         <Typography variant={isMobile() ? 'h5' : 'h4'}
           sx={{
             marginTop: '12px',
             marginLeft: '8px',
             marginBottom: '12px'
           }}
         color={'primary'}>
           {t("contactStep.yourName")}
         </Typography>
         <TextField
           placeholder={t("contactStep.enterYourName")}
           name={'userName'}
           // label="Your Name"
           sx={{ width: '100%' }}
           value={value}
           onChange={handleChange}
           InputLabelProps={{ style: { fontSize: 20 } }}
           InputProps={{
             style: { fontSize: 20 }
           }}
         />
       </Grid>
       <Grid xs={12} md={12}>
         <Typography variant={isMobile() ? 'h5' : 'h4'}
           sx={{ marginTop: '20px', marginLeft: '8px', marginBottom: '12px' }} color={'primary'}>
           {t("contactStep.yourPhoneNumber")}
         </Typography>
         <TextField
           placeholder={t("contactStep.enterYourPhone")}
           name={'phoneNumber'}
           sx={{ width: '100%' }}
           value={phone}
           onChange={handleChangePhone}
           InputLabelProps={{ style: { fontSize: 20 } }}
           InputProps={{
             style: { fontSize: 20 },
             startAdornment: (
               <InputAdornment position='start'>{'+'}</InputAdornment>
             )
           }}
         />
       </Grid>
       <Grid xs={12} md={12}>
         <Typography variant={isMobile() ? 'h5' : 'h4'}
           sx={{ marginTop: '20px', marginLeft: '8px', marginBottom: '12px' }} color={'primary'}>
           {t("contactStep.email")}
         </Typography>
         <TextField
           placeholder={t("contactStep.enterYourEmail")}
           name={'Email'}
           sx={{ width: '100%' }}
           value={email}
           onChange={handleChangeEmail}
           InputLabelProps={{ style: { fontSize: 20 } }}
           InputProps={{
             style: { fontSize: 20 }
           }}
         />
       </Grid>
     </Card> : (
     <Box>
       <Typography variant={"h1"}>
         {t("contactStep.orderReceived")}
       </Typography>
       <Typography
          variant={"body2"}
          sx={{
            mt: 1
          }}
       >
         {t("contactStep.ifYouWantNewData")},
         <span>
           <Button
            onClick={handleReload}
            color={"primary"}
            variant={"outlined"}
            sx={{
              ml: 1
            }}
           >
            {t("contactStep.clickHere")},
           </Button>
         </span>
       </Typography>
     </Box>
     )}
   </Box>
  );
}
