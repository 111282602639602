import { useRoutes } from 'react-router-dom';
import router from 'src/router';

import './i18n/config';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import { CssBaseline } from '@mui/material';
import ThemeProvider from './theme/ThemeProvider';
import ReactGA from 'react-ga';
import { useEffect } from 'react';

const trackingId = "G-SV9SPSQ32G";
ReactGA.initialize(trackingId, {
  // debug: true,
});

function App() {
  const content = useRoutes(router);
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [content]);

  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <CssBaseline />
        {content}
      </LocalizationProvider>
    </ThemeProvider>
  );
}
export default App;
