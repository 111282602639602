import React, { FC, useEffect, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks/storeHooks";
import { IPanel } from "../../../types/panelTypes";
import {
  CircularProgress, Container, Grid, Paper, styled
} from '@mui/material';
import { setCurrentPanel } from "../../../store/reducer/currentPanelSlice";
import { choosePanelsToShow, filterPanels, sortPanelsByEfficiency } from '../../../helpers/sortHelpers';
import { setFavoritesToLocalStorage } from '../../../store/reducer/favoriteSlice';
import { PanelCard } from './PanelCard';
import { ConfigTypes } from '../../../featureConfig';

export const
  PanelData: FC = () => {
  const panelData = useAppSelector((state) => state.panels.data);
  const currentPanel = useAppSelector((state) => state.currentPanel.data);
  const filters = useAppSelector((state) => state.filters);
  const favorite = useAppSelector((state) => state.favorite.data);
  const featureConfig = useAppSelector((state) => state.config.data);

  const isSolarDataFetched = useAppSelector((state) => state.solar.data?.content?.data.pvOutAnnual);
  const isPanelsFetched = useAppSelector((state) => state.panels.data?.content?.data.length);

  const dispatch = useAppDispatch();
  const panelsArray: IPanel[] | null = useMemo(() => {
    if (isPanelsFetched) {
      const unfilteredPanels = panelData?.content?.data;
      const chosenPanels = choosePanelsToShow(unfilteredPanels, favorite);
      const sortedPanels = sortPanelsByEfficiency(chosenPanels);
      return filterPanels(sortedPanels, filters?.data);
    } else {
      return [];
    }
  }, [panelData, filters, favorite?.isShowingFavorite, favorite?.isShowingCart]);

  useEffect(() => {
    if (
      panelsArray?.length &&
      panelsArray?.[0] !== currentPanel &&
      isPanelsFetched
    ) {
      setCurrentPanel(panelsArray[0])(dispatch);
    }
  }, [panelsArray]);

  useEffect(() => {
    setFavoritesToLocalStorage(favorite);
  }, [favorite]);

  const handlePanelClick = (panel: IPanel) => {
    setCurrentPanel(panel)(dispatch);
  };

  const isIframe = featureConfig.type === ConfigTypes.iframeFeatureConfig;
  const isLoading = useMemo(() => {
    if (isIframe) {
      return !isPanelsFetched;
    } else {
      return !isSolarDataFetched || !isPanelsFetched;
    }
  }, [isSolarDataFetched, isPanelsFetched]);
  return (
    <Grid container spacing={1}>
      <Grid item
        // lg={featureConfig.filters ? 11 : 12}
        // md={featureConfig.filters ? 11 : 12}
        xs={12}
      >
        <PanelRowCard elevation={3}>
          <PanelsRow>
            {isLoading ?
              <LoadingContainer>
                <CircularProgress />
              </LoadingContainer>
              : panelsArray.map((panel, index) => {
              return (
                <PanelCard
                  key={panel.id}
                  panel={panel}
                  handlePanelClick={handlePanelClick}
                  isLast={index === panelsArray.length - 1}
                />
              );
            })}
          </PanelsRow>
        </PanelRowCard>
      </Grid>
      {/*{featureConfig.filters &&*/}
      {/*  <Grid item lg={1} md={1} sm={12} xs={12}>*/}
      {/*    {panelsArray &&*/}
      {/*    <PanelFilters />*/}
      {/*    }*/}
      {/*  </Grid>*/}
      {/*}*/}
    </Grid>
  );
};

const PanelRowCard = styled(Paper)`
  overflow: hidden;
`;

const LoadingContainer = styled(Container)`
  height: 212px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const PanelsRow = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow-x: scroll;
  padding: 16px 0 16px 16px;
  box-sizing: border-box;

  ::-webkit-scrollbar {
    height: 12px
  }

  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.secondary.light};
  }

  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.primary.main};
    border-radius: 2px;
  }
`;
