import { IPanel } from '../../../types/panelTypes';
import { mockDistributors } from './distributors';
import { Countries } from '../countries';

export const singleMockPanel: IPanel = {
    id: 9999999993,
    distributor: mockDistributors[0],
    codeName: "100 W panel",
    egcType: "SOLAR_PANEL",
    picture: "https://www.asianpaints.com/content/dam/asian_paints/colours/swatches/K085.png.transform/cc-width-720-height-540/image.png",
    description: "",
    price: 100,
    currency: "EUR",
    specs: {
      maxPower_W: 100,
      voc_V: 0,
      isc_A: 0,
      cells: "MONOCRYSTALLINE",
      vmp_A: 0,
      weight_kg: 1000,
      imp_V: 0,
      operatingTemperature_C: null,
      maxEfficiency_PERCENT: null,
      productWarranty_Years: 15
    }
  };

export const defaultMockPanels: IPanel[] = [
  {
    id: 9999999999,
    distributor: mockDistributors[0],
    codeName: "Standard",
    egcType: "SOLAR_PANEL",
    picture: "https://envirio.me:8080/storage/image/1a6fc71e2119bcbb6a4441c16a408c69.jpg",
    description: "standardPanelText",
    price: 550,
    currency: "EUR",
    specs: {
      maxPower_W: 500,
      voc_V: 0,
      isc_A: 0,
      cells: "MONOCRYSTALLINE",
      vmp_A: 0,
      weight_kg: 1000,
      imp_V: 0,
      operatingTemperature_C: null,
      maxEfficiency_PERCENT: null,
      productWarranty_Years: 15
    }
  },
  {
    id: 9999999998,
    distributor: mockDistributors[0],
    codeName: "Premium",
    egcType: "SOLAR_PANEL",
    picture: "https://envirio.me:8080/storage/image/679a9760b83d2e2d0bdea32f797d128c.jpg",
    description: "premiumPanelText",
    price: 650,
    currency: "EUR",
    specs: {
      maxPower_W: 500,
      voc_V: 0,
      isc_A: 0,
      cells: "MONOCRYSTALLINE",
      vmp_A: 0,
      weight_kg: null,
      imp_V: 0,
      operatingTemperature_C: null,
      maxEfficiency_PERCENT: null,
      productWarranty_Years: 12
    }
  },
  {
    id: 9999999997,
    distributor: mockDistributors[0],
    codeName: "Ultra-Performance",
    egcType: "SOLAR_PANEL",
    picture: "https://envirio.me:8080/storage/image/3c30e6b8abc57be5ee002744adda114d.jpg",
    description: "ultraPanelText",
    price: 730,
    currency: "EUR",
    specs: {
      pcs: 1,
      maxPower_W: 500,
      voc_V: 0,
      isc_A: 0,
      cells: "MONOCRYSTALLINE",
      vmp_A: 0,
      weight_kg: null,
      imp_V: 0,
      operatingTemperature_C: null,
      maxEfficiency_PERCENT: null,
      productWarranty_Years: 15
    }
  },
  // {
  //   id: 9999999996,
  //   distributor: mockDistributors[1],
  //   codeName: "Test Set 4",
  //   egcType: "SOLAR_PANEL",
  //   picture: "https://envirio.me:8080/storage/image/1a6fc71e2119bcbb6a4441c16a408c69.jpg",
  //   description: "",
  //   price: 3580,
  //   currency: "EUR",
  //   specs: {
  //     maxPower_W: 3200,
  //     voc_V: 0,
  //     isc_A: 0,
  //     cells: "MONOCRYSTALLINE",
  //     vmp_A: 0,
  //     weight_kg: 1000,
  //     imp_V: 0,
  //     operatingTemperature_C: null,
  //     maxEfficiency_PERCENT: null,
  //     productWarranty_Years: 15
  //   }
  // },
  // {
  //   id: 9999999995,
  //   distributor: mockDistributors[1],
  //   codeName: "Test Set 5",
  //   egcType: "SOLAR_PANEL",
  //   picture: "https://envirio.me:8080/storage/image/679a9760b83d2e2d0bdea32f797d128c.jpg",
  //   description: "",
  //   price: 1150,
  //   currency: "EUR",
  //   specs: {
  //     maxPower_W: 1000,
  //     voc_V: 0,
  //     isc_A: 0,
  //     cells: "MONOCRYSTALLINE",
  //     vmp_A: 0,
  //     weight_kg: null,
  //     imp_V: 0,
  //     operatingTemperature_C: null,
  //     maxEfficiency_PERCENT: null,
  //     productWarranty_Years: 12
  //   }
  // },
  // {
  //   id: 9999999994,
  //   distributor: mockDistributors[1],
  //   codeName: "Test Set 6",
  //   egcType: "SOLAR_PANEL",
  //   picture: "https://envirio.me:8080/storage/image/3c30e6b8abc57be5ee002744adda114d.jpg",
  //   description: "",
  //   price: 6000,
  //   currency: "EUR",
  //   specs: {
  //     pcs: 1,
  //     maxPower_W: 5000,
  //     voc_V: 0,
  //     isc_A: 0,
  //     cells: "MONOCRYSTALLINE",
  //     vmp_A: 0,
  //     weight_kg: null,
  //     imp_V: 0,
  //     operatingTemperature_C: null,
  //     maxEfficiency_PERCENT: null,
  //     productWarranty_Years: 15
  //   }
  // }
]

export const BrazilMockPanels: IPanel[] = [
  {
    id: 888888888,
    distributor: mockDistributors[2],
    codeName: "Test Set 1",
    egcType: "SOLAR_PANEL",
    picture: "https://envirio.me:8080/storage/image/1a6fc71e2119bcbb6a4441c16a408c69.jpg",
    description: "",
    price: 17543,
    currency: "BRL",
    specs: {
      maxPower_W: 2670,
      voc_V: 0,
      isc_A: 0,
      cells: "MONOCRYSTALLINE",
      vmp_A: 0,
      weight_kg: 50,
      imp_V: 0,
      operatingTemperature_C: null,
      maxEfficiency_PERCENT: null,
      productWarranty_Years: 15
    }
  },
  {
    id: 888888887,
    distributor: mockDistributors[2],
    codeName: "Test Set 2",
    egcType: "SOLAR_PANEL",
    picture: "https://envirio.me:8080/storage/image/679a9760b83d2e2d0bdea32f797d128c.jpg",
    description: "",
    price: 23847,
    currency: "BRL",
    specs: {
      maxPower_W: 4000,
      voc_V: 0,
      isc_A: 0,
      cells: "MONOCRYSTALLINE",
      vmp_A: 0,
      weight_kg: null,
      imp_V: 0,
      operatingTemperature_C: null,
      maxEfficiency_PERCENT: null,
      productWarranty_Years: 12
    }
  },
  {
    id: 888888886,
    distributor: mockDistributors[2],
    codeName: "Test Set 3",
    egcType: "SOLAR_PANEL",
    picture: "https://envirio.me:8080/storage/image/3c30e6b8abc57be5ee002744adda114d.jpg",
    description: "",
    price: 29302,
    currency: "EUR",
    specs: {
      pcs: 1,
      maxPower_W: 5340,
      voc_V: 0,
      isc_A: 0,
      cells: "MONOCRYSTALLINE",
      vmp_A: 0,
      weight_kg: null,
      imp_V: 0,
      operatingTemperature_C: null,
      maxEfficiency_PERCENT: null,
      productWarranty_Years: 15
    }
  },
]

export const CountrySpecificMocks = new Map<Countries, IPanel[]>([
  [
    "DF",
    defaultMockPanels
  ],
]);
