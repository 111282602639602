import React, { FC, useCallback, useEffect, useState } from 'react';
import {
  Box,
  debounce,
  InputAdornment,
  styled,
  TextField, Typography
} from '@mui/material';
import equal from 'fast-deep-equal';
import { useAppDispatch, useAppSelector } from '../../../hooks/storeHooks';
import { updateConsumption } from '../../../store/reducer/electricSlice';
import { IConsumptionData } from '../../../helpers/constants/consumption';
import {
  IStringedData,
  objectPropertiesToNumber,
  objectPropertiesToString
} from '../../../helpers/transformations/objectTransformations';
import {
  getAnnualConsumptionByMonthlyBill,
  getMonthlyBillByConsumption,
  IBillToRecalculateConsumption,
  IConsumptionToRecalculateMonthly
} from '../../../helpers/calcHelpers';
import { isMobile } from '../../../helpers/isMobileHelper';
import { useTranslation } from 'react-i18next';

export const DataInputs: FC = () => {
  const [expanded, setExpanded] = useState<boolean>(true);
  const [inputData, setInputData] = useState<IStringedData<IConsumptionData> | null>(null)

  const { t } = useTranslation();

  const electricData = useAppSelector((state) => state.electric.data);

  const dispatch = useAppDispatch();
  const currencySign = electricData?.content?.currencySign;
  // const isSeparated = featureConfig.type === ConfigTypes.iframeFeatureConfig;
  const isSeparated = true;

  useEffect(() => {
    if (electricData?.content?.consumption && !inputData) {
      const stringInputData = objectPropertiesToString<IConsumptionData>(electricData.content.consumption);
      if (!equal(stringInputData, inputData)) {
        setInputData(stringInputData);
      }
    }
  }, [electricData?.content?.consumption]);

  useEffect(() => {
    console.log("electricData?.content?.consumption", electricData?.content?.consumption);
  }, [electricData?.content?.consumption]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = e;
    const newInputData = {
      ...inputData,
      [e.target.name]: value
    };
    const val = value.replaceAll(",", ".");
    if (e.target.name === "monthlyConsumption") {
      const normalizedValue = ((parseFloat(val)*12) || 0).toString();
      const objectToRecalculate = objectPropertiesToNumber<IStringedData<IConsumptionToRecalculateMonthly>>({
        annualConsumption: normalizedValue,
        electricityPrice: inputData?.electricityPrice
      });
      newInputData.annualConsumption = normalizedValue;
      newInputData.monthlyBill =
        getMonthlyBillByConsumption(objectToRecalculate).toFixed(2).toString();
    }
    if (e.target.name === "annualConsumption") {
      const objectToRecalculate = objectPropertiesToNumber<IStringedData<IConsumptionToRecalculateMonthly>>({
        annualConsumption: val,
        electricityPrice: inputData?.electricityPrice
      });
      newInputData.monthlyBill =
        getMonthlyBillByConsumption(objectToRecalculate).toFixed(2).toString();
    }
    if (e.target.name === "monthlyBill") {
      const objectToRecalculate = objectPropertiesToNumber<IStringedData<IBillToRecalculateConsumption>>({
        monthlyBill: val,
        electricityPrice: inputData?.electricityPrice
      });
      newInputData.annualConsumption =
        getAnnualConsumptionByMonthlyBill(objectToRecalculate).toFixed(2).toString();
    }
    if (e.target.name === "electricityPrice") {
      const objectToRecalculate = objectPropertiesToNumber<IStringedData<IBillToRecalculateConsumption>>({
        monthlyBill: inputData?.monthlyBill ,
        electricityPrice: val
      });
      newInputData.annualConsumption =
        getAnnualConsumptionByMonthlyBill(objectToRecalculate).toFixed(2).toString();
    }
    setInputData(newInputData);
    debouncedUpdateBills(
      newInputData
    );
  };

  const updateBills = (data: IStringedData<IConsumptionData>) => {
    const numberedData = objectPropertiesToNumber(data);
    updateConsumption(numberedData)(dispatch);
  };

  const debouncedUpdateBills = useCallback(debounce(updateBills, 700), []);
  const isDisabled = electricData?.content?.consumption?.annualConsumption === undefined;
  const monthlyConsumption = (Math.round((parseFloat(inputData?.annualConsumption)/12 || 0) * 100) / 100);
  return (
    <ElectricalDataCard
      sx={{
        // padding: {xs: "4px 20px;", md: "4px 300px;"}
        width: { xs: "90%", md: "60%", lg: "50%" }
      }}
    >
      <DataRow sx={{ marginTop: "0", flexDirection: "column"}}>
        <Typography variant={ isMobile() ? "h5" : "h4"} sx={{ marginTop: "8px", marginLeft: "8px", marginBottom: "0" }} color={"primary"}>
          {t("electricityPriceStep.buyingPrice")}
        </Typography>
        <TextField
          disabled={isDisabled || (isSeparated && !expanded)}
          name={"electricityPrice"}
          // sx={{ flex: 1 }}
          // label="El. buying price"
          // sx={{ width: "100%" }}
          value={inputData?.electricityPrice}
          onChange={handleChange}
          InputLabelProps={{style: {fontSize: 30}}}
          InputProps={{
            style: {fontSize: 30},
            startAdornment: (
              <InputAdornment position="start">{currencySign && `${currencySign}/kWh`}</InputAdornment>
            ),
          }}
        />
      </DataRow>
      {/*<DataRow sx={{ marginTop: { xs: "12px", lg: '24px' }, flexDirection: "column" }}>*/}
      {/*  <Typography variant={ isMobile() ? "h5" : "h4"} sx={{ marginTop: "8px", marginLeft: "8px", marginBottom: "0" }} color={"primary"}>*/}
      {/*    {t("electricityPriceStep.sellingPrice")}*/}
      {/*  </Typography>*/}
      {/*  <TextField*/}
      {/*    disabled={isDisabled || (isSeparated && !expanded)}*/}
      {/*    name={"electricitySellingPrice"}*/}
      {/*    // sx={{ width: "100%" }}*/}
      {/*    value={inputData?.electricitySellingPrice}*/}
      {/*    onChange={handleChange}*/}
      {/*    InputLabelProps={{style: {fontSize: 30}}}*/}
      {/*    InputProps={{*/}
      {/*      style: {fontSize: 30},*/}
      {/*      startAdornment: (*/}
      {/*        <InputAdornment position="start">{currencySign && `${currencySign}/kWh`}</InputAdornment>*/}
      {/*      ),*/}
      {/*    }}*/}
      {/*  />*/}
      {/*</DataRow>*/}
      <DataRow sx={{ marginTop: "32px" }}>
        <Typography variant={ isMobile() ? "h5" : "h4"}>
          {t("electricityPriceStep.monthlyConsumption")}: {monthlyConsumption.toString()}
        </Typography>
        {/*{ isSeparated*/}
        {/*  ? <TextField*/}
        {/*    disabled={isDisabled || (isSeparated && !expanded)}*/}
        {/*    name={'monthlyConsumption'}*/}
        {/*    sx={{ flex: 1 }}*/}
        {/*    label={t("electricityPriceStep.monthlyConsumption")}*/}
        {/*    value={monthlyConsumption.toString()}*/}
        {/*    onChange={handleChange}*/}
        {/*    InputProps={{*/}
        {/*      startAdornment: (*/}
        {/*        <InputAdornment position='start'>kWh</InputAdornment>*/}
        {/*      )*/}
        {/*    }} />*/}
        {/*  : <TextField*/}
        {/*    disabled={isDisabled || (isSeparated && !expanded)}*/}
        {/*    name={'annualConsumption'}*/}
        {/*    sx={{ flex: 1 }}*/}
        {/*    label='Annual Consumption'*/}
        {/*    value={inputData?.annualConsumption}*/}
        {/*    onChange={handleChange}*/}
        {/*    InputProps={{*/}
        {/*      startAdornment: (*/}
        {/*        <InputAdornment position='start'>kWh</InputAdornment>*/}
        {/*      )*/}
        {/*    }}*/}
        {/*  />}*/}
      </DataRow>
    </ElectricalDataCard>
  );
};

const ElectricalDataCard = styled(Box)`
  
`;

const DataRow = styled(Box)`
  display: flex;
  margin-bottom: 8px;
  margin-top: 8px;
  font-weight: bold;
  font-size: 20px;
  justify-content: space-between;
  gap: 12px;
`;
