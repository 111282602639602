import React, { FC } from "react";
import { Box, Card, Grid, styled, Tooltip, Typography } from '@mui/material';
import { useAppSelector } from "../../../hooks/storeHooks";
import { ErrorHighlight } from '../../../components/Text';
import { useTranslation } from 'react-i18next';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

export const AnnualStats: FC = () => {
  const electricData = useAppSelector((state) => state.electric.data);

  const { t } = useTranslation();

  const currencySign = electricData.content?.currencySign;

  return (
    <AnnualStatsCard
      sx={{
        width: { xs: "90%", md: "60%", lg: "50%" },
        padding: "12px 20px"
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} textAlign={{ sm: 'left' }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center"
            }}
          >
            <Tooltip arrow placement={"top"} title={t("monthlyBillStep.annualBillTooltip")}>
              <InfoOutlinedIcon fontSize={"small"} sx={{mr:"4px"}}/>
            </Tooltip>
            <Typography variant="h3" sx={{ fontSize: "16px" }}>
              {t("monthlyBillStep.annualBill")}: {" "}
              <ErrorHighlight>{electricData?.content?.bills.annualBill.toFixed(1) || 0} { currencySign || '€'}</ErrorHighlight>
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} textAlign={{ sm: 'left' }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center"
            }}
          >
            <Tooltip arrow placement={"top"} title={t("monthlyBillStep.annualCO2Tooltip")}>
              <InfoOutlinedIcon fontSize={"small"} sx={{mr:"4px"}}/>
            </Tooltip>
            <Typography variant="h3" sx={{ fontSize: "16px" }}>
              {t("monthlyBillStep.annualCo2Emission")}:{" "}
              <ErrorHighlight>{electricData?.content?.bills.annualCo2.toFixed(1) || 0} kg</ErrorHighlight>
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </AnnualStatsCard>
  );
};

const AnnualStatsCard = styled(Card)`
  padding: 8px 20px;
  border-radius: 8px;
`;
