import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppDispatch } from "../index";
import { ContentLoading, initialContentLoading } from "../commonTypes";
import { Countries } from "../../helpers/constants/countries";
import { consumptionByCountry, IConsumptionData } from '../../helpers/constants/consumption';
import countryToCurrency from "country-to-currency";
import {
  calculateBills, getMonthlyBillByConsumption
} from '../../helpers/calcHelpers';
import { client } from '../../api/AxiosInstance';
import { IRootObject } from '../../types/panelTypes';

export interface IPriceData {
  id: number;
  countryCode: string;
  priceDateSemester: string;
  priceValue: number;
  created: Date;
  priceCurrency: string;
  allTaxIncluded: boolean;
  measureUnit: string;
}

export interface ICountryData {
  country: string;
  countryCode: string;
  region?: string;
  city?: string;
  timezone?: string;
}

export interface ICoordinates {
  lat: number;
  lon: number;
}

export interface IBills {
  annualBill: number;
  monthlyBill: number;
  monthlyCo2: number;
  annualCo2: number;
}

export interface IElectricData {
  country: ICountryData;
  consumption: IConsumptionData;
  bills: IBills;
  currencySign?: string;
  coordinates?: ICoordinates;
}

export interface ICountryElectricData {
  data?: ContentLoading<null | IElectricData>;
}

const initialState: ICountryElectricData = {
  data: initialContentLoading(null),
};

const electricSlice = createSlice({
  name: "electricSlice",
  initialState,
  reducers: {
    startGetCountry(state) {
      state.data = {
        content: null,
        status: "loading",
        error: undefined,
      };
    },
    setCountryElectricData(state, action: PayloadAction<IElectricData>) {
      state.data = {
        content: action.payload,
        status: "loaded",
        error: undefined,
      };
    },
    setError(state, action: PayloadAction<Error>) {
      state.data = {
        content: null,
        status: "error",
        error: action.payload,
      };
    },
    setBills(state, action: PayloadAction<IBills>) {
      if (state.data?.content?.bills) {
        state.data.content.bills = action.payload;
      }
    },
    setConsumptionData(state, action: PayloadAction<IConsumptionData>) {
      if (state?.data?.content) {
        state.data.content.consumption = action.payload;
      }
    },
    setCoordinatesData(state, action: PayloadAction<ICoordinates>) {
      if (state?.data?.content) {
        state.data.content.coordinates = action.payload;
      }
    },
  },
});

const {
  setCountryElectricData,
  setConsumptionData,
  setBills,
  setCoordinatesData
} = electricSlice.actions;
export default electricSlice.reducer;

export const getCurrentElPrice = async (countryCode: string, isBusiness: boolean) => {
  try {
    const price = await client.get<IRootObject<IPriceData>>(
      "/api/price",
      { params: { countryCode: countryCode, isBusiness: isBusiness } }
    );
    return price.data.data.priceValue;
  } catch (e) {
    console.log(e);
  }
}

export const getConsumptionByCountry = async (
  country: Countries,
  isBusiness: boolean
): Promise<IConsumptionData> => {
  try {
    let val = consumptionByCountry[country];
    if (!val) {
      val = consumptionByCountry.DF;
    }
    val = {...val};
    const currentPrice = await getCurrentElPrice(country, isBusiness);
    if (currentPrice) {
      val.electricityPrice = currentPrice;
      const rawBill = getMonthlyBillByConsumption({
        annualConsumption: val.annualConsumption,
        electricityPrice: val.electricityPrice
      });
      val.monthlyBill = Math.round((rawBill + Number.EPSILON) * 100) / 100;
    }
    return val as IConsumptionData;
  } catch (e) {
    console.log("Error, ", e);
  }
};

export const setCountryData = async (data: ICountryData, isBusiness: boolean, coordinates: ICoordinates, dispatch: AppDispatch) => {
  const consumption: IConsumptionData = await getConsumptionByCountry(
    data.countryCode as Countries,
    isBusiness
  );
  const bills: IBills = calculateBills(consumption);
  const combinedData: IElectricData = {
    country: data,
    consumption,
    bills,
    coordinates: coordinates
  };
  combinedData.currencySign = getCurrencySign(data.countryCode as Countries);
  console.log("COMBINED JKDSFHISFHGIDSFJ;S", combinedData);
  dispatch(setCountryElectricData(combinedData));
}

export const setCoordinates = (data: ICoordinates) => (dispatch: AppDispatch) => {
  dispatch(setCoordinatesData(data));
}

export const updateConsumption =
  (newConsumption: IConsumptionData) => (dispatch: AppDispatch) => {
    dispatch(setConsumptionData(newConsumption));
    recalculateBills(newConsumption)(dispatch);
  };

export const recalculateBills =
  (newConsumption: IConsumptionData) => (dispatch: AppDispatch) => {
    const bills: IBills = calculateBills(newConsumption);
    dispatch(setBills(bills));
  };

export const getCurrencySign = (country: Countries) => {
  return countryToCurrency[country];
}
