import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppDispatch } from "../index";
import { Currencies, mockCurrencies } from '../../helpers/constants/mocks/currencies';

export interface ICurrenciesInfo {
  currencies: Currencies | null;
}

const initialState: ICurrenciesInfo = { currencies: null };

const currenciesSlice = createSlice({
  name: "currenciesSlice",
  initialState,
  reducers: {
    setCurrenciesState(state, action: PayloadAction<Currencies>) {
      state.currencies = action.payload;
    },
  },
});

const { setCurrenciesState } = currenciesSlice.actions;
export default currenciesSlice.reducer;


export const getCurrencies = async (dispatch: AppDispatch)  => {
  const api = "https://api.exchangerate-api.com/v4/latest/USD";

  const currency = await fetch(`${api}`);
  let currencyJson = await currency.json();

  if(!currencyJson) {
    currencyJson = mockCurrencies;
  }

  dispatch(setCurrenciesState(currencyJson));
  return currencyJson;
}
