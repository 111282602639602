import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { StyledImage } from './BatteryInfo';

export const InstallationInfo = () => {

  const { t } = useTranslation();

  return (
    <Box
      sx={{
        mt: 2,
        padding: 2
      }}
    >
      <Typography variant={'h3'} color={"text.primary"}>
        {t("resultStep.sidebar.installation")}
      </Typography>
      <Typography
        variant={"body2"}
        color={"text.primary"}
        fontSize={"20px"}
        sx={{
          mt: 2
        }}
      >
        {t("resultStep.sidebar.installationText")}

      </Typography>
      <StyledImage
        src='https://dysonenergyservices.co.uk/wp-content/uploads/2022/10/iStock-1222813535-1024x625.jpg'
        alt=''

      />
    </Box>
  );
}
