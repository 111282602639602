import React, { useEffect, useRef, useState } from 'react';
import "react-chat-elements/dist/main.css"
import { MessageList, MessageType } from 'react-chat-elements';

import {
  Box, Button,
  styled, TextField
} from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../hooks/storeHooks';
import { sendMessageToChat } from '../../../store/reducer/aiChatSlice';
import { EnviIsWritingMessage } from './components/EnviIsWritingMessage';
import { useTranslation } from 'react-i18next';

export const playMessageSound = () => {
  const audio = new Audio("/static/audio/messageSound.mp3");
  audio.volume = 0.5;
  void audio.play();
}

export const AiChat = () => {
  const [message, setMessage] = useState<string>("");
  const ref = useRef();
  const chatAnchorRef = useRef<any>(null);
  const dispatch = useAppDispatch();
  const messages = useAppSelector((state) => state.aiChat.data.content.messages);
  const status = useAppSelector((state) => state.aiChat.data.status);
  const isLoading = status === "loading";

  const { t } = useTranslation();

  useEffect(() => {
    const height = chatAnchorRef.current.scrollHeight;
    chatAnchorRef.current.scroll({ top: height });
  }, []);

  useEffect(() => {
    setTimeout(() => {
      const height = chatAnchorRef.current.scrollHeight;
      chatAnchorRef.current.scroll({ top: height, behavior: "smooth" });
    }, 300);
  }, [messages]);

  const sendMessage = () => {
    const trimmedMessage = message.trim();
    if (trimmedMessage.length > 0 && !isLoading) {
      setMessage("");
      sendMessageToChat(trimmedMessage, messages)(dispatch);
    }
  }


  const handleMessageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = e;
    setMessage(value);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && !isLoading) {
      e.preventDefault();
      sendMessage();
    }
  }

  const initMessage: MessageType = {
    id: "01010101",
    replyButton: false,
    removeButton: false,
    focus: false,
    status: "read",
    notch: false,
    retracted: false,
    titleColor: "#5569ff",
    date: Date.now(),
    forwarded: false,
    position:"left",
    type:"text",
    title:"Envi",
    text: t("aiChat.initialMessage")
  }

  const messagesWithInitial = [initMessage, ...messages];

  return (
    <Wrapper className="Mui-FixedWrapper">
      <MessagesWrapper ref={chatAnchorRef}>
        <MessageList
          lockable={true}
          toBottomHeight={'100%'}
          referance={ref}
          dataSource={messagesWithInitial}
        />
        {isLoading && <EnviIsWritingMessage/>}
      </MessagesWrapper>
      <BottomBlock>
        <InputWrapper>
          <TextField
            value={message}
            onChange={handleMessageChange}
            onKeyDown={handleKeyDown}
            sx={{width: "100%", height: "75px"}}
            multiline
            autoFocus
            id={"chat-input"}
            label={t("aiChat.typeYourQuestions")}
            name={"chat-input"}
            rows={2}
          />
        </InputWrapper>
        <Button variant={'outlined'} onClick={sendMessage}>{t("send")}</Button>
      </BottomBlock>
    </Wrapper>
  );
}

const Wrapper = styled(Box)`
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

const InputWrapper = styled(Box)`
  width: 60%;
  height: 90px;
  padding: 8px;
  flex-direction: row;
  border-radius: 4px;
`;

const BottomBlock = styled("div")`
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 100%;
  flex-direction: row;
`;

const MessagesWrapper = styled("div")`
  max-height: calc(100% - 100px);
  overflow-y: scroll;
  padding: 10px;
  ::-webkit-scrollbar {
    width: 10px;
  }
  ::-webkit-scrollbar-track {
    background: ${({theme}) => theme.colors.alpha.white[50]};
  }
  ::-webkit-scrollbar-thumb {
    background: ${({theme}) => theme.colors.primary.main};
    border-radius: 2px;
  }
`;
