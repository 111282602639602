import {
  Tooltip,
  Badge,
  TooltipProps,
  tooltipClasses,
  styled,
  useTheme
} from '@mui/material';
import { Link } from 'react-router-dom';

type logoVariants = "green" | "white";

interface IProps {
  logoVariant?: logoVariants
}

const Logo = ({ logoVariant = "white" }: IProps) => {
  const theme = useTheme();

  let logoFile: string = "/static/images/boosteco-logo/logo.png";
  if (logoVariant === "green") {
    logoFile = "/static/images/boosteco-logo/logo-green.png"
  }

  return (
    <TooltipWrapper
      title="Envirio Client Dashboard"
      arrow
    >
      <LogoWrapper to="/">
        {/*<Badge*/}
        {/*  sx={{*/}
        {/*    '.MuiBadge-badge': {*/}
        {/*      fontSize: theme.typography.pxToRem(11),*/}
        {/*      right: -5,*/}
        {/*      top: 2*/}
        {/*    }*/}
        {/*  }}*/}
        {/*  overlap="circular"*/}
        {/*  color="success"*/}
        {/*  badgeContent="0.0.1"*/}
        {/*>*/}
        <img src={logoFile} alt='logo' height={20} />
        {/*</Badge>*/}
      </LogoWrapper>
    </TooltipWrapper>
  );
}

export default Logo;

const LogoWrapper = styled(Link)(
  ({ theme }) => `
        color: ${theme.palette.text.primary};
        display: flex;
        text-decoration: none;
        width: 53px;
        margin: 0 auto;
        font-weight: ${theme.typography.fontWeightBold};
`
);

const TooltipWrapper = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.colors.alpha.trueWhite[100],
    color: theme.palette.getContrastText(theme.colors.alpha.trueWhite[100]),
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 'bold',
    borderRadius: theme.general.borderRadiusSm,
    boxShadow:
      '0 .2rem .8rem rgba(7,9,25,.18), 0 .08rem .15rem rgba(7,9,25,.15)'
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.colors.alpha.trueWhite[100]
  }
}));
