import { Box, Card, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import React from 'react';
import { useAppDispatch, useAppSelector } from '../hooks/storeHooks';
import { setPropertyTypeToggle } from '../store/reducer/propertyTypeSlice';
import { useTranslation } from 'react-i18next';

export type propertyType = "household" | "business";

export const PropertyTypeRadioGroup = () => {
  // const [value, setValue] = useState<propertyType>("household");
  const dispatch = useAppDispatch();
  const propertyTypeVal = useAppSelector((state) => state.propertyType.data);

  const { t } = useTranslation();

  // useEffect(() => {
  //   setPropertyTypeToggle(propertyTypeVal || "household")(dispatch);
  // }, []);
  //
  // useEffect(() => {
  //   if (!value && propertyTypeVal) {
  //     setValue(propertyTypeVal);
  //   }
  // }, [propertyTypeVal]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value as propertyType;
    // setValue(newValue);
    setPropertyTypeToggle(newValue)(dispatch);
  };

  return(
    <FormControl sx={{width: "100%"}}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          mb: "4px"
        }}
      >
        <FormLabel id="demo-controlled-radio-buttons-group"> {t("coordinatesStep.propertyType")}</FormLabel>
        {/*<Tooltip arrow placement={"top"} title={t("coordinatesStep.propertyTooltip")}>*/}
        {/*  <InfoOutlinedIcon fontSize={"small"} sx={{ ml: "4px" }} />*/}
        {/*</Tooltip>*/}
      </Box>
      <Card sx={{ padding: "12px" }}>
        <RadioGroup
          sx={{ display: "flex", flexDirection: "row" }}
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={propertyTypeVal}
          onChange={handleChange}
        >
          <FormControlLabel value="household" control={<Radio />} label={t("coordinatesStep.household")} />
          <FormControlLabel value="business" control={<Radio />} label={t("coordinatesStep.business")} />
        </RadioGroup>
      </Card>
    </FormControl>
  );
}
