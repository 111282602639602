import { IPanel } from "../types/panelTypes";
import { IFilterData } from '../store/reducer/filterSlice';
import { IFavoriteData } from '../store/reducer/favoriteSlice';

export const sortPanelsByEfficiency = (panels: IPanel[]) => {
  const newPanels = panels.map((panel) => {
    const efficiencyFactor = panel.specs.maxPower_W / panel.price;
    return { ...panel, efficiencyFactor };
  });
  return newPanels.sort((first: IPanel, second: IPanel) => {
    if (!first.efficiencyFactor || !second.efficiencyFactor) {
      return 0;
    }
    if (first.efficiencyFactor < second.efficiencyFactor) {
      return 1;
    }
    if (first.efficiencyFactor > second.efficiencyFactor) {
      return -1;
    }
    return 0;
  });
};

const isFiltersIntact = (filters: IFilterData) => {
  return filters.distributorIds
    || filters.highestPrice
    || filters.lowestPrice
    || filters.lowestKwh
    || filters.highestKwh;
}

export const choosePanelsToShow = (panels: IPanel[], favorite: IFavoriteData) => {
  if (favorite.isShowingCart) {
    return favorite.panelsInCart;
  }
  if (favorite.isShowingFavorite) {
    return favorite.favoritePanels;
  }
  return  panels;
}

export const filterPanels = (panels: IPanel[], filters: IFilterData) => {
  if (isFiltersIntact) {
    let filteredPanels = [...panels];
    if (filters.distributorIds) {
      filteredPanels = panels.filter((el) =>
        filters?.distributorIds.find((id) => id === el.distributor.id)
      );
    }
    if (filters.lowestPrice) {
      filteredPanels = filteredPanels.filter((el) => el.price >= filters.lowestPrice)
    }
    if (filters.highestPrice) {
      filteredPanels = filteredPanels.filter((el) => el.price <= filters.highestPrice)
    }
    if (filters.lowestKwh) {
      filteredPanels = filteredPanels.filter((el) => el.specs.maxPower_W >= filters.lowestKwh)
    }
    if (filters.highestKwh) {
      filteredPanels = filteredPanels.filter((el) => el.specs.maxPower_W <= filters.highestKwh)
    }
    return filteredPanels;
  } else {
    return panels;
  }
}
