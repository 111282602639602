import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { propertyType } from '../../components/PropertyTypeRadioGroup';
import { AppDispatch } from '../index';

interface IPropertyType {
  data?: propertyType
}

const initialState: IPropertyType = {
  data: "household"
};

const propertyTypeSlice = createSlice({
  name: "propertyTypeSlice",
  initialState,
  reducers: {
    setPropertyType(state, action: PayloadAction<propertyType>) {
      state.data = action.payload;
    },
  },
});

export const { setPropertyType } = propertyTypeSlice.actions;
export default propertyTypeSlice.reducer;

export const setPropertyTypeToggle = (propertyType: propertyType) => (dispatch: AppDispatch) => {
  dispatch(setPropertyType(propertyType));
};
