import React, { useEffect, useState } from 'react';
import { Card, MenuItem, OutlinedInput, Select, SelectChangeEvent, styled, Typography } from '@mui/material';
import { useAppSelector } from '../../../../hooks/storeHooks';
import { useTranslation } from 'react-i18next';

interface IAvailableCountry {
  country: string;
  flagUrl: string;
  countryCode: string;
}

const availableCountries: IAvailableCountry[] = [
  {
    country: "Moldova",
    flagUrl: "",
    countryCode: "MD"
  },
  {
    country: "Romania",
    flagUrl: "",
    countryCode: "RO"
  }
];

interface IProps {
  setCoordinatesByCountry: (code: any) => void
}

export const MainCoordsBlock = ({ setCoordinatesByCountry }: IProps) => {
  const [selected, setSelected] = useState<null | string>(null);

  const featureConfig = useAppSelector((state) => state.config.data);
  // const coordinates = useAppSelector((state) => state.electric?.data?.content?.coordinates);
  const country = useAppSelector((state) => state.electric?.data?.content?.country?.country);
  const countryCode = useAppSelector((state) => state.electric?.data?.content?.country?.countryCode);

  useEffect(() => {
    if (!selected && countryCode) {
      setSelected(countryCode);
    }
  }, [selected, countryCode]);

  const { t } = useTranslation();

  // const getCoordsElement = () => {
  //   if (coordinates) {
  //     return <Wrapper>
  //       <CoordHeader>
  //         lat: <CoordHighlight>{coordinates?.lat?.toFixed(2) || 0}</CoordHighlight>
  //       </CoordHeader>
  //       <CoordHeader>
  {/*        long:{" "}*/}
  {/*        <CoordHighlight>{coordinates?.lon?.toFixed(2) || 0}</CoordHighlight>*/}
  //       </CoordHeader>
  //       <CoordHeader sx={{ minWidth: "120px" }}>
  //         <CoordHighlight>
  //           {country || "Undefined"}
  //         </CoordHighlight>
  //       </CoordHeader>
  //     </Wrapper>
  //   } else {
  //     return <Wrapper
  //       sx={{
  //         justifyContent: "start"
  {/*      }}*/}
  {/*    >*/}
  {/*      <CoordHeader>*/}
  //         {t("coordinatesStep.coordinatesHint")}
  //       </CoordHeader>
  //       <Tooltip arrow placement={"top"} title={t("coordinatesStep.coordinatesTooltip")}>
  //         <InfoOutlinedIcon fontSize={"small"} />
  //       </Tooltip>
  //     </Wrapper>
  //   }
  // }

  const handleChange = (e: SelectChangeEvent) => {
    const {
      target: { value },
    } = e;
    console.log("SDgfhjkdsbhjklgfshildsfghiklfdsihlj", value);
    setSelected(value);
    const val = availableCountries.find(el => el.countryCode === value);
    console.log("valsdm,. fhjlo;dsjghfigyiraeaygovg", val);
    setCoordinatesByCountry(val);
  }

  return (
    <CoordinatesCard isDockedTop={featureConfig.topBar}>
      <Wrapper sx={{ mt: 1 }}>
        <CoordHeader sx={{ minWidth: "120px" }}>
          {t("coordinatesStep.yourCountry")} <CoordHighlight>{country || "Undefined"}</CoordHighlight>
        </CoordHeader>
      </Wrapper>
      <Wrapper sx={{ mb: 1 }}>
        <Select
          value={selected}
          sx={{minWidth: "150px", minHeight: "65px"}}
          onChange={handleChange}
          input={<OutlinedInput  id="select-chip" />}
        >
          {availableCountries?.map(el => (
            <MenuItem key={el.countryCode} value={el.countryCode}>
              {el.country}
            </MenuItem>
          ))}
        </Select>
        <Typography variant={"h5"} sx={{ml: 2}}>
          {t("coordinatesStep.youCanPick")}
        </Typography>
      </Wrapper>
      {/*{coordinates ? <MapPopup /> : <Box sx={{ height: "70px" }}/>}*/}
    </CoordinatesCard>
  );
}

interface ICoordinatesCardProps {
  readonly isDockedTop: boolean;
}

const CoordinatesCard = styled(Card)<ICoordinatesCardProps>`
  padding: 4px 20px;
  margin-top: 4px;
  margin-bottom: 12px;
  width: 100%;
`;

const Wrapper = styled("div")`
  display: flex;
  flex-direction: row;
  //width: 65%;
  align-items: center;
  //justify-content: space-between;
`;

const CoordHeader = styled("h3")`
  margin-right: 8px;
  margin-left: 8px;
  font-weight: bold;
`;

const CoordHighlight = styled('span')(
  ({ theme }) => `
    color: ${theme.colors.primary.main}
`
);

// interface ICoordButtonProps {
//   readonly isInit: boolean;
//   isIframe?: boolean;
// }
//
// const GetCoordinatesButton = styled(Button)<ICoordButtonProps>`
//   @keyframes vibration {
//     0% { transform: translate(0, 0) rotate(0deg); }
//     25% { transform: translate(3px, 3px) rotate(3deg); }
//     50% { transform: translate(0, 0) rotate(0eg); }
//     75% { transform: translate(-3px, 3px) rotate(-3deg); }
//     100% { transform: translate(0, 0) rotate(0deg); }
//   }
//   @keyframes smallVibration {
//     0% { transform: translate(0, 0) rotate(0deg); }
//     25% { transform: translate(2px, 2px) }
//     50% { transform: translate(0, 0) rotate(0eg); }
//     75% { transform: translate(-2px, 2px) }
//     100% { transform: translate(0, 0) rotate(0deg); }
//   }
// `;
