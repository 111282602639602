import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppDispatch } from "../index";
import { IElectricData } from './electricSlice';
import { client } from '../../api/AxiosInstance';
import { IRootObject } from '../../types/panelTypes';
import { ILoadingStatus } from '../commonTypes';

export interface IContactData {
  name: string;
  phone: string;
  email: string;
  status: ILoadingStatus;
}

const initialState: IContactData = {
  name: "",
  phone: "",
  email: "",
  status: "init"
};

const contactDataSlice = createSlice({
  name: "contactDataSlice",
  initialState,
  reducers: {
    setName(state, action: PayloadAction<string>) {
      state.name = action.payload;
    },
    setPhone(state, action: PayloadAction<string>) {
      state.phone = action.payload;
    },
    setEmail(state, action: PayloadAction<string>) {
      state.email = action.payload;
    },
    setStatus(state, action: PayloadAction<ILoadingStatus>) {
      state.status = action.payload;
    },
  },
});

const { setName, setEmail, setPhone, setStatus } = contactDataSlice.actions;
export default contactDataSlice.reducer;

export const setUserName =
  (data: string) => async (dispatch: AppDispatch) => {
    dispatch(setName(data));
  };

export const setUserPhone =
  (data: string) => async (dispatch: AppDispatch) => {
    dispatch(setPhone(data));
  };

export const setUserEmail =
  (data: string) => async (dispatch: AppDispatch) => {
    dispatch(setEmail(data));
  };


export const sendContactData =
  (contactData: IContactData, electricData: IElectricData, isBatteryIncluded: boolean) => async (dispatch: AppDispatch) => {
    try {
      const calculationData = { ...electricData, batteryIncluded: isBatteryIncluded };
      dispatch(setStatus("loading"));
      const res = await client.post<IRootObject<void>>(
        "/order/new",
        { email: contactData.email, phone: contactData.phone, name: contactData.name, calculation: calculationData }
      );
      dispatch(setStatus("loaded"));
    } catch (e) {
      dispatch(setStatus("error"));
    }
  };


