import { Card, CardActionArea, CardContent, CardMedia, styled, Typography } from '@mui/material';
import React, { FC } from 'react';
import { IPanel } from '../../../types/panelTypes';
import { useAppSelector } from '../../../hooks/storeHooks';
import { ConfigTypes } from '../../../featureConfig';

interface IPanelCardProps {
  panel: IPanel;
  handlePanelClick: (panel: IPanel) => void;
  isLast: boolean;
}

export const PanelCard: FC<IPanelCardProps> = ({ panel, handlePanelClick }) => {
  const featureConfig = useAppSelector((state) => state.config.data);
  const currentPanel = useAppSelector((state) => state.currentPanel.data);
  // const pvOut = useAppSelector((state) => state.solar?.data?.content?.data?.pvOutAnnual);
  const currencySign = useAppSelector(
    (state) => state.electric.data?.content?.currencySign
  );

  // const currentPositionGeneration =
  //   ((pvOut || 1000) *
  //     panel.specs.maxPower_W) /
  //   1000;

  const isIframe = featureConfig.type === ConfigTypes.iframeFeatureConfig;
  // const isShowSeller = !isIframe || (isIframe && testUrlForMock());

  return (
    <CardContainer key={panel.id}>
      <StyledCard
        elevation={isIframe ? 0 : 1}
        key={panel.id}
        isSelected={panel.id === currentPanel?.id}
        isIframe={isIframe}
      >
        <CardActionArea onClick={() => handlePanelClick(panel)}>
          {/*{isShowSeller && <Paper*/}
          {/*  elevation={1}*/}
          {/*  sx={{*/}
          {/*    position: 'absolute',*/}
          {/*    padding: '2px 4px',*/}
          {/*    margin: '4px'*/}
          {/*  }}*/}
          {/*>*/}
          {/*  <Typography variant='h5'>*/}
          {/*    Seller: {panel.distributor?.companyName}*/}
          {/*  </Typography>*/}
          {/*</Paper>}*/}
          <CardMedia
            component="img"
            height="100"
            image={panel.picture}
            alt={panel.codeName}
          />
          <CardContent>
            <Typography
              sx={{
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
              gutterBottom
              variant="h5"
              component="div"
            >
              {panel.codeName}
            </Typography>
            <Typography variant="body2" sx={{ fontSize: "13px" }} color="text.secondary">
              Price: {panel.price.toLocaleString()} {currencySign}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Power: {panel.specs.maxPower_W} kWp
            </Typography>
            {/*{!isIframe && (*/}
            {/*  <Typography variant="body2" color="text.secondary">*/}
            {/*    Annual generation: {currentPositionGeneration.toFixed(2)}{" "}*/}
            {/*    kWh*/}
            {/*  </Typography>*/}
            {/*)}*/}
          </CardContent>
        </CardActionArea>
      </StyledCard>
    </CardContainer>
  );
}

const CardContainer = styled("div")`
  width: 170px;
  height: 212px;
  flex: 0 0 auto;
`;

interface IStyledCardProps {
  isSelected: boolean;
  isIframe: boolean;
}

const StyledCard = styled(Card)<IStyledCardProps>`
  margin-right: 16px;
  max-width: 180px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background-color: ${({ isSelected, theme }) => isSelected ? theme.colors.secondary.light : "whitesmoke"};
  border: solid ${({ isSelected, theme }) => isSelected ? theme.colors.primary.main : "transparent"};
  border-width: ${({ isIframe }) => isIframe 
          ? "1px" 
          : 0
  };
  margin-bottom: ${({isSelected}) => isSelected ? "12px" : "8px"};
  margin-top: ${({isSelected}) => isSelected ? "4px" : "8px"};;

  transition: all .2s;
`;
