import { Countries } from '../countries';
import { ICoordinates } from '../../../store/reducer/electricSlice';

export const CoordinatesMock = new Map<Countries, ICoordinates>([
  [
    "RO",
    {
      lat: 44.43,
      lon: 26.09,
    }
  ],
  [
    "MD",
    {
      lat: 47.02,
      lon: 28.84,
    }
  ],
  [
    "DF",
    {
      lat: 47.02,
      lon: 28.84,
    }
  ],
]);
