import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IPanel } from '../../types/panelTypes';

export interface IFavoriteData {
  favoritePanels: IPanel[],
  panelsInCart: IPanel[],
  isShowingFavorite: boolean,
  isShowingCart: boolean,
}

export interface IFavorite {
  data: IFavoriteData
}

const blankInitialState: IFavorite = {
  data: {
    favoritePanels: [],
    panelsInCart: [],
    isShowingFavorite: false,
    isShowingCart: false
  }
};

export const setFavoritesToLocalStorage = (favorite: IFavoriteData) => {
  localStorage.setItem("currentEnvirioFavorites", JSON.stringify(favorite));
}

const initialState = (): IFavorite => {
  const savedFavoritesString = localStorage.getItem("currentEnvirioFavorites");
  if (savedFavoritesString) {
    const savedFavorites = JSON.parse(savedFavoritesString);
    return { data: savedFavorites as IFavoriteData }
  }
  return blankInitialState;
};

const favoriteSlice = createSlice({
  name: "favoriteSlice",
  initialState,
  reducers: {
    addToCart(state, action: PayloadAction<IPanel>) {
      state.data.panelsInCart = [...state.data.panelsInCart, action.payload];
    },
    addToFavorite(state, action: PayloadAction<IPanel>) {
      state.data.favoritePanels = [...state.data.favoritePanels, action.payload];
    },
    removeFromCart(state, action: PayloadAction<IPanel>) {
      state.data.panelsInCart = state.data.panelsInCart.filter((el) => el.id !== action.payload.id);
    },
    removeFromFavorite(state, action: PayloadAction<IPanel>) {
      state.data.favoritePanels = state.data.favoritePanels.filter((el) => el.id !== action.payload.id);
    },
    toggleShowCart(state) {
      if (!state.data.isShowingCart) {
        state.data.isShowingFavorite = false;
      }
      state.data.isShowingCart = !state.data.isShowingCart;
    },
    toggleShowFavorite(state) {
      if (!state.data.isShowingFavorite) {
        state.data.isShowingCart = false;
      }
      state.data.isShowingFavorite = !state.data.isShowingFavorite;
    },
    clearFavoriteShow(state) {
      state.data.isShowingFavorite = false;
      state.data.isShowingCart = false;
    }
  },
});

export const {
  addToCart,
  addToFavorite,
  removeFromCart,
  removeFromFavorite,
  toggleShowCart,
  toggleShowFavorite,
  clearFavoriteShow
} = favoriteSlice.actions;

export default favoriteSlice.reducer;
