import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { RouteObject } from 'react-router';

import SidebarLayout from 'src/layouts/SidebarLayout';
import BaseLayout from 'src/layouts/BaseLayout';

import SuspenseLoader from 'src/components/SuspenseLoader';
import SignInSide from './content/pages/Auth/Login';
import SignUp from './content/pages/Auth/Register';
import * as React from 'react';
import { HorizontalLinearStepper } from './components/Stepper/Stepper';
import { ZeroStep } from './components/Stepper/ZeroStep';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Dashboards

const Calculator = Loader(lazy(() => import('src/content/dashboards/Calculator')));
const Contact = Loader(lazy(() => import('src/content/dashboards/Contact/ContactPage')));

const Distributors = Loader(lazy(() => import('src/content/dashboards/Distributors')));
const DistributorPage = Loader(lazy(() => import('src/content/dashboards/DistributorPage')));


const Messenger = Loader(
  lazy(() => import('src/content/applications/Messenger'))
);

// Applications
const UserSettings = Loader(
  lazy(() => import('src/content/applications/Users/settings'))
);

// Status

const Status404 = Loader(
  lazy(() => import('src/content/pages/Status/Status404'))
);
const Status500 = Loader(
  lazy(() => import('src/content/pages/Status/Status500'))
);
const StatusComingSoon = Loader(
  lazy(() => import('src/content/pages/Status/ComingSoon'))
);
const StatusMaintenance = Loader(
  lazy(() => import('src/content/pages/Status/Maintenance'))
);

const routes: RouteObject[] = [
  {
    path: '',
    element: <BaseLayout/>,
    children: [
      {
        path: '/',
        element: <Navigate to="dashboards" replace />
      },
      {
        path : "login",
        element: <SignInSide/>
      },
      {
        path : "register",
        element: <SignUp/>
      },
      {
        path: 'status',
        children: [
          {
            path: '',
            element: <Navigate to="404" replace />
          },
          {
            path: '404',
            element: <Status404 />
          },
          {
            path: '500',
            element: <Status500 />
          },
          {
            path: 'maintenance',
            element: <StatusMaintenance />
          },
          {
            path: 'coming-soon',
            element: <StatusComingSoon />
          }
        ]
      },
      {
        path: '*',
        element: <Status404 />
      }
    ]
  },
  {
    path: 'dashboards',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="calculator" replace />
      },
      {
        path: 'calculator',
        element: <Calculator />,
        children: [
          {
            path: '',
            element: <ZeroStep/>
          },
          {
            path: 'step/:number',
            element: <HorizontalLinearStepper/>
          },
        ]
      },
      {
        path: 'contact',
        element: <Contact />
      },
      {
        path: 'distributors/:id',
        element: <DistributorPage />
      },
      {
        path: 'distributors',
        element: <Distributors />,
      },
      {
        path: 'messenger/:id',
        element: <Messenger />
      },
      {
        path: 'messenger',
        element: <Messenger />
      }
    ]
  },
  {
    path: 'management',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="profile/settings" replace />
      },
      {
        path: 'profile',
        children: [
          {
            path: '',
            element: <Navigate to="settings" replace />
          },
          {
            path: 'settings',
            element: <UserSettings />
          }
        ]
      }
    ]
  },
];

export default routes;
